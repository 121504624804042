import React, { useEffect } from "react";
import Styles from "./Portfolio.module.css";
import GLightbox from "glightbox";
import Isotope from "isotope-layout";
import imagesLoaded from "imagesloaded";
import { Link } from "react-router-dom";
import { Ratio } from "react-bootstrap";
import PortfolioItem from "./PortfolioItem";
import { useGetDoc } from "../../../hooks/useGetDoc";
import { useCollectionDocs } from "../../../hooks/useCollectionDocs";
import { documentId } from "firebase/firestore";

const Portfolio = () => {
  const { getSingleDocWithQuery, data: userData, isPending: isPendingUser, error } = useGetDoc();
  useEffect(() => {
    getSingleDocWithQuery("users", [[documentId(), "==", process.env.REACT_APP_ADMIN_ID]]);
  }, []);
  const {
    documents: categories,
    isPending: isCatPending,
    getMultipleDocs: getMultipleCat
  } = useCollectionDocs();

  const {
    documents: portfolio,
    isPending: isPortfolioPending,
    getMultipleDocs: getMultiplePortfolio
  } = useCollectionDocs();

  const loadCategory = () => {
    getMultipleCat({
      _collection: `portfoliocat`,
      _query: false,
      orderByField: "createdAt",
      orderByOption: "asc"
    });
  };

  const loadPortfolio = () => {
    getMultiplePortfolio({
      _collection: `portfolio`,
      _query: false,
      orderByField: "createdAt",
      orderByOption: "asc"
    });
  };

  useEffect(() => {
    if (categories && portfolio) {
      setTimeout(() => {
        updateBox();
      }, 1000);
    }
  }, [isCatPending, isPortfolioPending]);

  useEffect(() => {
    loadCategory();
    loadPortfolio();
  }, []);

  const updateBox = () => {
    const glightbox = GLightbox({
      selector: ".glightbox"
    });

    // Initiate Isotope layout and filters
    document.querySelectorAll(".isotope-layout").forEach(function (isotopeItem) {
      let layout = isotopeItem.getAttribute("data-layout") ?? "masonry";
      let filter = isotopeItem.getAttribute("data-default-filter") ?? "*";
      let sort = isotopeItem.getAttribute("data-sort") ?? "original-order";

      let initIsotope;
      imagesLoaded(isotopeItem.querySelector(".isotope-container"), function () {
        initIsotope = new Isotope(isotopeItem.querySelector(".isotope-container"), {
          itemSelector: ".isotope-item",
          layoutMode: layout,
          filter: filter,
          sortBy: sort
        });
      });

      isotopeItem.querySelectorAll(".isotope-filters li").forEach(function (filters) {
        filters.addEventListener(
          "click",
          function () {
            isotopeItem
              .querySelector(`.isotope-filters .${Styles.filterActive}`)
              .classList.remove(Styles.filterActive);
            this.classList.add(Styles.filterActive);
            initIsotope.arrange({
              filter: this.getAttribute("data-filter")
            });
          },
          false
        );
      });
    });

    // Cleanup function
    return () => {
      glightbox.destroy();
    };
  };
  return (
    <section id="portfolio" className={`${Styles.portfolio} section`}>
      <div className="container section-title" data-aos="fade-up">
        <h2>Portfolio</h2>
        {userData && Array.isArray(userData) && userData.length > 0 && <p>{userData[0].portfolio_area}</p>}
      </div>

      <div className="container">
        <div
          className="isotope-layout"
          data-default-filter="*"
          data-layout="masonry"
          data-sort="original-order"
        >
          <ul
            className={`${Styles.portfolioFilters} isotope-filters`}
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <li data-filter="*" className={Styles.filterActive}>
              All
            </li>
            {categories && categories.length > 0 && (
              <>
                {categories.map((cat, i) => {
                  return (
                    <li data-filter={`.filter-${cat.name}`} key={i}>
                      {cat.name}
                    </li>
                  );
                })}
              </>
            )}
          </ul>
          {portfolio && portfolio.length > 0 && (
            <div className="row gy-4 isotope-container" data-aos="fade-up" data-aos-delay="200">
              <>
                {portfolio.map((item, i) => {
                  if (item.active) {
                    return <PortfolioItem key={i} item={item} />;
                  }
                })}
              </>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
