import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, Card, Spinner } from "react-bootstrap";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { useGetDoc } from "../../../../hooks/useGetDoc";
import { useCollectionDocs } from "../../../../hooks/useCollectionDocs";
import { useSetDoc } from "../../../../hooks/useSetDoc";
import { useAddDocs } from "../../../../hooks/useAddDocs";
import { useDeleteDocs } from "../../../../hooks/useDeleteDocs";
import { useUserDetailsContext } from "../../../../context/UserDetailsContext";

const Service = () => {
  const { getSingleDocWithQuery, data, isPending, error } = useGetDoc();
  const { documents, isPending: isServicePending, getMultipleDocs } = useCollectionDocs();
  const { firebaseSetDoc, isPending: isUpdating, isConfirmed, error: updateError } = useSetDoc();
  const { addADoc, isPending: isAddPending, error: addError } = useAddDocs();
  const { deleteDocument, isPending: deletePending } = useDeleteDocs();
  const { userData, isPending: isUserPending, id } = useUserDetailsContext();

  const [showModal, setShowModal] = useState(false);
  const [editService, setEditService] = useState(null);

  const [iconClassforView, setIconClassforView] = useState("");

  const loadServices = () => {
    getMultipleDocs({
      _collection: "services",
      _query: false,
      orderByField: "createdAt",
      orderByOption: "asc"
    });
  };

  useEffect(() => {
    loadServices();
  }, []);

  useEffect(() => {
    getSingleDocWithQuery("services", false);
  }, [getSingleDocWithQuery]);

  const initialValues = {
    name: "",
    iconClass: "",
    details: ""
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Service name is required"),
    iconClass: Yup.string().required("Service icon class is required"),
    details: Yup.string().required("Details are required")
  });

  const handleEditService = (service) => {
    setEditService(service);
    setShowModal(true);
  };

  const handleDeleteService = async (id) => {
    await deleteDocument("services", id);
    loadServices();
  };

  const formik = useFormik({
    initialValues: {
      service_area: userData?.service_area || ""
    },
    validationSchema: Yup.object({
      service_area: Yup.string().required("Service area is required")
    }),
    onSubmit: async (values) => {
      await firebaseSetDoc("users", { service_area: values.service_area }, id, true);
    }
  });

  return (
    <div className="container mt-5">
      <div>
        <Form onSubmit={formik.handleSubmit} className="mb-4 ">
          <Form.Group>
            <Form.Label>Service Area</Form.Label>
            <Form.Control
              as="textarea"
              name="service_area"
              value={formik.values.service_area}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.service_area && formik.errors.service_area}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.service_area}</Form.Control.Feedback>
          </Form.Group>
          <Button type="submit" disabled={formik.isSubmitting} className="mt-2">
            {formik.isSubmitting ? (
              <Spinner animation="border" size="sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              "Update Service Area"
            )}
          </Button>
        </Form>
      </div>

      <div className="row my-3">
        <div className="col-12 text-end">
          <Button
            variant="primary mt-3"
            onClick={() => {
              setEditService(null);
              setShowModal(true);
            }}
          >
            Add New Service
          </Button>
        </div>
      </div>

      {isServicePending ? (
        <p>Loading...</p>
      ) : (
        <Row>
          {documents && documents.length > 0 ? (
            documents.map((service) => (
              <Col md={12} key={service.id}>
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title>{service.name}</Card.Title>
                    <Card.Text>{service.iconClass}</Card.Text>
                    <Card.Text>{service.details}</Card.Text>
                    <div className="d-flex justify-content-between">
                      <Button variant="secondary" onClick={() => handleEditService(service)}>
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        disabled={deletePending}
                        onClick={() => handleDeleteService(service.id)}
                      >
                        Delete
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <p>No services found.</p>
          )}
        </Row>
      )}

      {error && <p className="text-danger">{error}</p>}
      {updateError && <p className="text-danger">{updateError}</p>}
      {addError && <p className="text-danger">{addError}</p>}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{editService ? "Edit Service" : "Add New Service"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={editService || initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
              if (editService) {
                await firebaseSetDoc("services", values, editService.id);
              } else {
                await addADoc("services", values);
              }
              loadServices();
              setShowModal(false);
              resetForm();
              setEditService(null);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Service Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.name && errors.name}
                  />
                  <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Service Icon Class <i class={iconClassforView}></i>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="iconClass"
                    value={values.iconClass}
                    onChange={(e) => {
                      setIconClassforView(e.target.value);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    isInvalid={touched.iconClass && errors.iconClass}
                  />
                  <Form.Control.Feedback type="invalid">{errors.iconClass}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Details</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="details"
                    value={values.details}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.details && errors.details}
                  />
                  <Form.Control.Feedback type="invalid">{errors.details}</Form.Control.Feedback>
                </Form.Group>
                <Button variant="primary mt-3" type="submit" disabled={isAddPending || isUpdating}>
                  {isAddPending || isUpdating ? (
                    <Spinner animation="border" size="sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    "Save"
                  )}
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Service;
