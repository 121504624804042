import React, { useState, useEffect } from "react";
import { Card, Button, Modal, Spinner, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useCollectionDocs } from "../../../../hooks/useCollectionDocs";
import { useDeleteDocs } from "../../../../hooks/useDeleteDocs";
import { useSetDoc } from "../../../../hooks/useSetDoc";
import { useFormik } from "formik";
import { useUserDetailsContext } from "../../../../context/UserDetailsContext";
import * as Yup from "yup";

const Portfolio = () => {
  const { documents: portfolios, isPending: isPortfolioPending, getMultipleDocs } = useCollectionDocs();
  const { deleteDocument, isPending: deletePending } = useDeleteDocs();
  const { firebaseSetDoc, isPending: isUpdating, error: updateError } = useSetDoc();
  const { userData, isPending: isUserPending, id } = useUserDetailsContext();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletePortfolioId, setDeletePortfolioId] = useState(null);
  const [updatingId, setUpdatingId] = useState(null);

  useEffect(() => {
    loadPortfolios();
  }, []);

  const loadPortfolios = () => {
    getMultipleDocs({
      _collection: "portfolio",
      _query: false,
      orderByField: "createdAt",
      orderByOption: "asc"
    });
  };

  const handleDeletePortfolio = async (id) => {
    try {
      await deleteDocument("portfolio", id);
      closeDeleteModal();
      loadPortfolios();
    } catch (error) {
      console.error("Error deleting portfolio:", error);
    }
  };

  const toggleActiveInactive = async (id, status) => {
    await firebaseSetDoc("portfolio", { active: status }, id, true);
    loadPortfolios();
    setUpdatingId(null);
  };

  const openDeleteModal = (id) => {
    setDeletePortfolioId(id);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setDeletePortfolioId(null);
    setShowDeleteModal(false);
  };

  const formik = useFormik({
    initialValues: {
      portfolio_area: userData?.portfolio_area || ""
    },
    validationSchema: Yup.object({
      portfolio_area: Yup.string().required("Protfolio area is required")
    }),
    onSubmit: async (values) => {
      console.log(values);
      await firebaseSetDoc("users", { portfolio_area: values.portfolio_area }, id, true);
    }
  });

  return (
    <div className="container mt-5">
      <div>
        <Form onSubmit={formik.handleSubmit} className="mb-4 ">
          <Form.Group>
            <Form.Label>Portfolio Area</Form.Label>
            <Form.Control
              as="textarea"
              name="portfolio_area"
              value={formik.values.portfolio_area}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.portfolio_area && formik.errors.portfolio_area}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.portfolio_area}</Form.Control.Feedback>
          </Form.Group>
          <Button type="submit" disabled={formik.isSubmitting} className="mt-2">
            {formik.isSubmitting ? (
              <Spinner animation="border" size="sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              "Update Portfolio Area"
            )}
          </Button>
        </Form>
      </div>

      <div className="row">
        <div className="col-12 mb-3 text-end">
          <Button as={Link} to="/dashboard/portfolio/add" variant="primary">
            Add New Portfolio
          </Button>
        </div>
      </div>

      <div className="row">
        <>
          {portfolios && Array.isArray(portfolios) && portfolios.length > 0 && (
            <>
              {portfolios.map((portfolio) => {
                return (
                  <div key={portfolio.id} className="col-lg-4 col-md-6 mb-4">
                    <Card>
                      <Card.Img variant="top" src={portfolio.image_url} />
                      <Card.Body>
                        <Card.Title>{portfolio.title}</Card.Title>
                        <Card.Text>
                          Categories: {portfolio.categories.map((item) => item.label).join(" ")}
                        </Card.Text>
                        <Button variant="danger" onClick={() => openDeleteModal(portfolio.id)}>
                          Delete
                        </Button>{" "}
                        <Button as={Link} to={`/dashboard/portfolio/${portfolio.id}`} variant="primary">
                          Edit
                        </Button>{" "}
                        <Button
                          onClick={() => {
                            setUpdatingId(portfolio.id);
                            toggleActiveInactive(portfolio.id, !portfolio.active);
                          }}
                          variant={portfolio.active ? "success" : "secondary"}
                        >
                          {isUpdating && updatingId == portfolio.id ? (
                            <Spinner size="sm" animation="border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </Spinner>
                          ) : (
                            <>{portfolio.active ? "Active" : "Inactive"}</>
                          )}
                        </Button>
                      </Card.Body>
                    </Card>
                  </div>
                );
              })}
            </>
          )}
        </>
      </div>

      <Modal show={showDeleteModal} onHide={closeDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this portfolio item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteModal}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => handleDeletePortfolio(deletePortfolioId)}
            disabled={deletePending}
          >
            {deletePending ? (
              <Spinner animation="border" size="sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              "Delete"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Portfolio;
