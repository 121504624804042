import React, { useEffect, useState } from "react";
import Styles from "./Contact.module.css";
import { Link } from "react-router-dom";
import { useCollectionDocs } from "../../../hooks/useCollectionDocs";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAddDocs } from "../../../hooks/useAddDocs";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useGetDoc } from "../../../hooks/useGetDoc";
import { documentId } from "firebase/firestore";

const Contact = () => {
  const [showModal, setShowModal] = useState(false);
  const { getSingleDocWithQuery, data, isPending, error } = useGetDoc();
  const [userData, setUserData] = useState({});
  useEffect(() => {
    getSingleDocWithQuery("users", [[documentId(), "==", process.env.REACT_APP_ADMIN_ID]]);
  }, []);
  const {
    documents: socialLinks,
    isPending: isSocialPending,
    getMultipleDocs: getSocialLinks
  } = useCollectionDocs();
  const loadSocial = () => {
    getSocialLinks({
      _collection: `social_links`,
      _query: false
    });
  };

  useEffect(() => {
    loadSocial();
  }, []);

  useEffect(() => {
    if (data && Array.isArray(data) && data.length > 0) {
      setUserData(data[0]);
    }
  }, [data]);

  const validationSchema = Yup.object({
    name: Yup.string().required("Your Name is required"),
    email: Yup.string().email("Invalid email address").required("Your Email is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required")
  });
  const { addADoc, isPending: isAddPending, error: addError } = useAddDocs();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: ""
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        await addADoc("messages", {
          ...values,
          read_status: false
        });

        // await emailjs.send(
        //   "service_id", // Replace with your EmailJS service ID
        //   "template_id", // Replace with your EmailJS template ID
        //   {
        //     from_name: values.name,
        //     from_email: values.email,
        //     subject: `<b>Portfolio Contact</b> - ${values.subject}`,
        //     message: `
        //       Name: ${values.name}<br />
        //       Email: ${values.email}<br />
        //       Subject: ${values.subject}<br />
        //       Message: ${values.message}
        //     `,
        //     to_email: process.env.REACT_APP_MESSAGE_RECEIVING_EMAIL
        //   },
        //   "user_id" // Replace with your EmailJS user ID
        // );
        setShowModal(true);
        resetForm();
      } catch (err) {
        console.error("Error adding document: ", err);
      } finally {
        setSubmitting(false);
      }
    }
  });

  return (
    <section id="contact" className={`${Styles.contact} section`}>
      <div className="container section-title" data-aos="fade-up">
        <h2>Contact</h2>
        <p>{userData.contact_area}</p>
      </div>

      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-4">
          <div className="col-lg-5">
            <div className={Styles.infoWrap}>
              <div className={`${Styles.infoItem} d-flex`} data-aos="fade-up" data-aos-delay="200">
                <i className="bi bi-geo-alt flex-shrink-0"></i>
                <div>
                  <h3>Address</h3>
                  <p>{userData.address}</p>
                </div>
              </div>

              <div className={`${Styles.infoItem} d-flex`} data-aos="fade-up" data-aos-delay="300">
                <i className="bi bi-telephone flex-shrink-0"></i>
                <div>
                  <h3>Call Me</h3>
                  <a href={`tel: ${userData.phone}`}>{userData.phone}</a>
                </div>
              </div>

              <div
                className={`${Styles.infoItem} ${Styles.infoItem_last} d-flex`}
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <i className="bi bi-envelope flex-shrink-0"></i>
                <div>
                  <h3>Email Me</h3>
                  <a href={`mailto: ${userData.email}`}>{userData.email}</a>
                </div>
              </div>

              {socialLinks && socialLinks.length === 1 && (
                <div className={`${Styles.socialLinks} text-center`}>
                  {socialLinks[0].github && socialLinks[0].github_active && (
                    <Link to={socialLinks[0].github} className="github" target="_blank">
                      <i className="bi bi-github"></i>
                    </Link>
                  )}
                  {socialLinks[0].facebook && socialLinks[0].facebook_active && (
                    <Link to={socialLinks[0].facebook} className="facebook" target="_blank">
                      <i className="bi bi-facebook"></i>
                    </Link>
                  )}
                  {socialLinks[0].instagram && socialLinks[0].instagram_active && (
                    <Link to={socialLinks[0].instagram} className="instagram" target="_blank">
                      <i className="bi bi-instagram"></i>
                    </Link>
                  )}
                  {socialLinks[0].email && socialLinks[0].email_active && (
                    <Link to={`mailto: ${socialLinks[0].email}`} className="envelope-at-fill" target="_blank">
                      <i className="bi bi-envelope-at-fill"></i>
                    </Link>
                  )}
                  {socialLinks[0].skype && socialLinks[0].skype_active && (
                    <Link to={socialLinks[0].skype} className="google-plus" target="_blank">
                      <i className="bi bi-skype"></i>
                    </Link>
                  )}
                  {socialLinks[0].linkedin && socialLinks[0].linkedin_active && (
                    <Link to={socialLinks[0].linkedin} className="linkedin" target="_blank">
                      <i className="bi bi-linkedin"></i>
                    </Link>
                  )}
                  {socialLinks[0].medium && socialLinks[0].medium_active && (
                    <Link to={socialLinks[0].medium} className="medium" target="_blank">
                      <i className="bi bi-medium"></i>
                    </Link>
                  )}
                </div>
              )}

              <iframe
                src={userData.map}
                frameBorder="0"
                style={{ border: 0, width: "100%", height: "270px" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>

          <div className="col-lg-7">
            <form
              onSubmit={formik.handleSubmit}
              className={Styles.phpEmailForm}
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="row gy-4">
                <div className="col-md-6">
                  <label htmlFor="name-field" className="pb-2">
                    Your Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name-field"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    required=""
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className={Styles.errorMessage}>{formik.errors.name}</div>
                  ) : null}
                </div>

                <div className="col-md-6">
                  <label htmlFor="email-field" className="pb-2">
                    Your Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email-field"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    required=""
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className={Styles.errorMessage}>{formik.errors.email}</div>
                  ) : null}
                </div>

                <div className="col-md-12">
                  <label htmlFor="subject-field" className="pb-2">
                    Subject
                  </label>
                  <input
                    type="text"
                    name="subject"
                    id="subject-field"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.subject}
                    required=""
                  />
                  {formik.touched.subject && formik.errors.subject ? (
                    <div className={Styles.errorMessage}>{formik.errors.subject}</div>
                  ) : null}
                </div>

                <div className="col-md-12">
                  <label htmlFor="message-field" className="pb-2">
                    Message
                  </label>
                  <textarea
                    name="message"
                    id="message-field"
                    className="form-control"
                    rows="10"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                    required=""
                  ></textarea>
                  {formik.touched.message && formik.errors.message ? (
                    <div className={Styles.errorMessage}>{formik.errors.message}</div>
                  ) : null}
                </div>

                <div className="col-md-12 text-center">
                  {isAddPending ? (
                    <div className={Styles.loading}>Loading</div>
                  ) : (
                    <>
                      {addError && <div className={Styles.errorMessage}>{addError}</div>}
                      <div className={Styles.sentMessage}>Your message has been sent. Thank you!</div>
                      <button type="submit" disabled={formik.isSubmitting}>
                        {formik.isSubmitting ? (
                          <Spinner animation="border" size="sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : (
                          "Send Message"
                        )}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Message Sent</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your message has been sent successfully. Thank you!</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default Contact;
