import { useState } from "react";
import { Toast, Alert } from "react-bootstrap";

export default function Toasts({ type, message, id }) {
  const [show, setShow] = useState(true);
  return (
    <>
      <Toast onClose={() => setShow(false)} show={show} delay={4500} autohide>
        <Toast.Body className="p-0">
          <Alert className="m-0" variant={type} onClose={() => setShow(false)} dismissible>
            <h6 className="m-0 text-center">{message}</h6>
          </Alert>
        </Toast.Body>
      </Toast>
    </>
  );
}
