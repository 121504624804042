import React, { useEffect, useState } from "react";
import { useCollectionDocs } from "../../../../hooks/useCollectionDocs";
import { useSetDoc } from "../../../../hooks/useSetDoc"; // Import the custom hook

import { Button, Modal, Table, Container, Row, ButtonGroup, Spinner } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  github: Yup.string().url("Invalid URL").required("GitHub URL is required"),
  github_active: Yup.boolean(),
  facebook: Yup.string().url("Invalid URL").required("Facebook URL is required"),
  facebook_active: Yup.boolean(),
  instagram: Yup.string().url("Invalid URL").required("Instagram URL is required"),
  instagram_active: Yup.boolean(),
  skype: Yup.string().url("Invalid URL").required("Skype URL is required"),
  skype_active: Yup.boolean(),
  linkedin: Yup.string().url("Invalid URL").required("LinkedIn URL is required"),
  linkedin_active: Yup.boolean()
});

const SocialLinks = () => {
  const { documents, isPending, getMultipleDocs } = useCollectionDocs();
  const { firebaseSetDoc, isPending: isUpdating, isConfirmed, error } = useSetDoc();
  const [data, setData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    github: "",
    github_active: false,
    facebook: "",
    facebook_active: false,
    instagram: "",
    instagram_active: false,
    email: "",
    email_active: false,
    skype: "",
    skype_active: false,
    linkedin: "",
    linkedin_active: false,
    medium: "",
    medium_active: false
  });

  const loadData = () => {
    getMultipleDocs({
      _collection: `social_links`,
      _query: false
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (documents && documents.length > 0) {
      setData(documents[0]);
      setModalData(documents[0]);
    }
  }, [documents]);

  const editData = (data) => {
    setModalData(data);
    setShowModal(true);
  };

  const toggleActive = async (field) => {
    const updatedData = { ...data, [field]: !data[field] };
    await firebaseSetDoc("social_links", updatedData, data.id, true);
    loadData(); // Refresh the data after updating
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      await firebaseSetDoc("social_links", values, data.id, true);
      loadData(); // Refresh the data after updating
    } catch (error) {
      console.error("Error updating document: ", error);
    } finally {
      setSubmitting(false);
      setShowModal(false);
    }
  };

  return (
    <>
      <Container>
        <Row>
          <div className="col-12 text-end my-3">
            <Button variant="success" onClick={() => editData(data)}>
              Edit Data
            </Button>
          </div>
        </Row>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Social Network</th>
              <th>URL</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data && (
              <>
                {Object.keys(data).map((key) => {
                  if (key.includes("_active") || key == "id" || key == "updateAt") return null;
                  return (
                    <tr key={key}>
                      <td>{key}</td>
                      <td>{data[key]}</td>
                      <td>
                        <ButtonGroup>
                          <Button
                            variant={data[`${key}_active`] ? "secondary" : "success"}
                            size="sm"
                            onClick={() => toggleActive(`${key}_active`)}
                          >
                            {isPending ? (
                              <Spinner animation="border" size="sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </Spinner>
                            ) : (
                              <>{data[`${key}_active`] ? "Deactivate" : "Activate"}</>
                            )}
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </Table>
      </Container>

      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={modalData}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
            enableReinitialize={true}
          >
            {({ isSubmitting }) => (
              <Form>
                {["github", "facebook", "instagram", "email", "skype", "linkedin", "medium"].map((social) => (
                  <div className="form-group" key={social}>
                    <label htmlFor={social}>{social.charAt(0).toUpperCase() + social.slice(1)}</label>
                    <Field type="text" name={social} className="form-control" />
                    <ErrorMessage name={social} component="div" className="text-danger" />
                    <div className="form-check">
                      <Field type="checkbox" name={`${social}_active`} className="form-check-input" />
                      <label htmlFor={`${social}_active`} className="form-check-label">
                        Active
                      </label>
                    </div>
                  </div>
                ))}
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleModalClose}>
                    Close
                  </Button>
                  <Button variant="primary" type="submit" disabled={isSubmitting || isUpdating}>
                    {isSubmitting || isUpdating ? (
                      <Spinner animation="border" size="sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      "Save changes"
                    )}
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SocialLinks;
