import React, { useEffect, useRef, useState } from "react";
import Styles from "./Header.module.css";
import { Link, useLocation } from "react-router-dom";
import { useCollectionDocs } from "../../hooks/useCollectionDocs";

export const Header = () => {
  const headerRef = useRef(null);
  const headerToggleBtnRef = useRef(null);
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.hash);
  const {
    documents: socialLinks,
    isPending: isSocialPending,
    getMultipleDocs: getSocialLinks
  } = useCollectionDocs();

  useEffect(() => {
    setActiveLink(location.hash);
  }, [location.hash]);

  const headerToggle = () => {
    if (headerRef.current && headerToggleBtnRef.current) {
      headerRef.current.classList.toggle(Styles.headerShow);

      headerToggleBtnRef.current.classList.toggle("bi-list");
      headerToggleBtnRef.current.classList.toggle("bi-x");
    }
  };

  function handleMobileToggle() {
    if (headerRef.current && headerRef.current.classList.contains(Styles.headerShow)) {
      headerToggle();
    }
  }

  const loadSocial = () => {
    getSocialLinks({
      _collection: `social_links`,
      _query: false
    });
  };

  useEffect(() => {
    loadSocial();
  }, []);

  return (
    <>
      <header ref={headerRef} className={`${Styles.header} d-flex flex-column`}>
        <i
          ref={headerToggleBtnRef}
          onClick={headerToggle}
          className={`${Styles.headerToggle} d-xl-none bi bi-list`}
        ></i>

        <div className={Styles.profileImg}>
          <img src="/frontend/aididalam.jpeg" alt="" className="img-fluid rounded-circle" />
        </div>

        <Link to="index.html" className={`${Styles.logo} d-flex align-items-center justify-content-center`}>
          <h1 className={Styles.sitename}>Aidid Alam</h1>
        </Link>

        {socialLinks && socialLinks.length === 1 && (
          <div className={`${Styles.socialLinks} text-center`}>
            {socialLinks[0].github && socialLinks[0].github_active && (
              <Link to={socialLinks[0].github} className="github" target="_blank">
                <i className="bi bi-github"></i>
              </Link>
            )}
            {socialLinks[0].facebook && socialLinks[0].facebook_active && (
              <Link to={socialLinks[0].facebook} className="facebook" target="_blank">
                <i className="bi bi-facebook"></i>
              </Link>
            )}
            {socialLinks[0].instagram && socialLinks[0].instagram_active && (
              <Link to={socialLinks[0].instagram} className="instagram" target="_blank">
                <i className="bi bi-instagram"></i>
              </Link>
            )}
            {socialLinks[0].email && socialLinks[0].email_active && (
              <Link to={`mailto: ${socialLinks[0].email}`} className="envelope-at-fill" target="_blank">
                <i className="bi bi-envelope-at-fill"></i>
              </Link>
            )}
            {socialLinks[0].skype && socialLinks[0].skype_active && (
              <Link to={socialLinks[0].skype} className="google-plus" target="_blank">
                <i className="bi bi-skype"></i>
              </Link>
            )}
            {socialLinks[0].linkedin && socialLinks[0].linkedin_active && (
              <Link to={socialLinks[0].linkedin} className="linkedin" target="_blank">
                <i className="bi bi-linkedin"></i>
              </Link>
            )}
            {socialLinks[0].medium && socialLinks[0].medium_active && (
              <Link to={socialLinks[0].medium} className="medium" target="_blank">
                <i className="bi bi-medium"></i>
              </Link>
            )}
          </div>
        )}

        <nav className={Styles.navmenu}>
          <ul>
            <li>
              <a
                href="#hero"
                onClick={() => handleMobileToggle("#hero")}
                className={activeLink === "#hero" ? Styles.active : ""}
              >
                <i className={`bi bi-house ${Styles.navicon}`}></i> Home
              </a>
            </li>
            <li>
              <a
                href="#about"
                onClick={() => handleMobileToggle("#about")}
                className={activeLink === "#about" ? Styles.active : ""}
              >
                <i className={`bi bi-person ${Styles.navicon}`}></i> About
              </a>
            </li>
            <li>
              <a
                href="#resume"
                onClick={() => handleMobileToggle("#resume")}
                className={activeLink === "#resume" ? Styles.active : ""}
              >
                <i className={`bi bi-file-earmark-text ${Styles.navicon}`}></i> Resume
              </a>
            </li>
            <li>
              <a
                href="#portfolio"
                onClick={() => handleMobileToggle("#portfolio")}
                className={activeLink === "#portfolio" ? Styles.active : ""}
              >
                <i className={`bi bi-images ${Styles.navicon}`}></i> Portfolio
              </a>
            </li>
            <li>
              <a
                href="#services"
                onClick={() => handleMobileToggle("#services")}
                className={activeLink === "#services" ? Styles.active : ""}
              >
                <i className={`bi bi-hdd-stack ${Styles.navicon}`}></i> Services
              </a>
            </li>
            <li>
              <a
                href="#contact"
                onClick={() => handleMobileToggle("#contact")}
                className={activeLink === "#contact" ? Styles.active : ""}
              >
                <i className={`bi bi-envelope ${Styles.navicon}`}></i> Contact
              </a>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};
