import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Table, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useCollectionDocs } from "../../../../hooks/useCollectionDocs";
import { useSetDoc } from "../../../../hooks/useSetDoc";
import { useAddDocs } from "../../../../hooks/useAddDocs";
import { useDeleteDocs } from "../../../../hooks/useDeleteDocs";

const PortfolioCat = () => {
  const { documents, isPending: isCategoryPending, getMultipleDocs } = useCollectionDocs("portfoliocat");
  const { firebaseSetDoc, isPending: isUpdating, error: updateError } = useSetDoc();
  const { addADoc, isPending: isAddPending, error: addError } = useAddDocs();
  const { deleteDocument, isPending: deletePending } = useDeleteDocs();

  const [showModal, setShowModal] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);

  useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = () => {
    getMultipleDocs({
      _collection: "portfoliocat",
      _query: false,
      orderByField: "createdAt",
      orderByOption: "asc"
    });
  };

  const formik = useFormik({
    initialValues: {
      name: ""
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required")
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        if (editingCategory) {
          await firebaseSetDoc(
            "portfoliocat",
            {
              name: values.name,
              identity: values.name.toLowerCase()
            },
            editingCategory.id,
            true
          );
        } else {
          await addADoc("portfoliocat", {
            name: values.name,
            identity: values.name.toLowerCase()
          });
        }
        resetForm();
        setEditingCategory(null);
        setShowModal(false);
        loadCategories();
      } catch (error) {
        console.error("Error saving category:", error);
      }
    }
  });

  const handleEditCategory = (category) => {
    setEditingCategory(category);
    setShowModal(true);
    formik.setValues({
      name: category.name
    });
  };

  const handleDeleteCategory = async (id) => {
    try {
      await deleteDocument("portfoliocat", id);
      loadCategories();
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row my-3">
        <div className="col-12 text-end">
          <Button
            variant="primary mt-3"
            onClick={() => {
              setEditingCategory(null);
              setShowModal(true);
            }}
          >
            Add New Category
          </Button>
        </div>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {documents && documents.length > 0 ? (
            documents.map((category) => (
              <tr key={category.id}>
                <td>{category.name}</td>
                <td>
                  <Button variant="primary" size="sm" onClick={() => handleEditCategory(category)}>
                    Edit
                  </Button>{" "}
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDeleteCategory(category.id)}
                    disabled={deletePending}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2">No categories found.</td>
            </tr>
          )}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{editingCategory ? "Edit Category" : "Add New Category"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.name && formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary mt-3" type="submit" disabled={isAddPending || isUpdating}>
              {isAddPending || isUpdating ? (
                <Spinner animation="border" size="sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "Save"
              )}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {updateError && <p className="text-danger">{updateError}</p>}
      {addError && <p className="text-danger">{addError}</p>}
    </div>
  );
};

export default PortfolioCat;
