import { createContext, useContext, useReducer } from 'react';

const ToastStateContext = createContext({ toasts: [] });

function ToastReducer(state, action) {
  switch (action.type) {
    case 'ADD_TOAST': {
      return {
        ...state,
        toasts: [...state.toasts, action.toast],
      };
    }
    default: {
      throw new Error('unhandled action type');
    }
  }
}

export function ToastProvider({ children }) {
  const [state, dispatch] = useReducer(ToastReducer, {
    toasts: [],
  });
  return (
    <ToastStateContext.Provider value={{...state, dispatch}}>
        {children}
    </ToastStateContext.Provider>
  );
}

export const useToastStateContext = () => useContext(ToastStateContext);
