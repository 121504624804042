import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, Card, Spinner } from "react-bootstrap";
import { Formik, FieldArray, useFormik } from "formik";
import * as Yup from "yup";
import { useGetDoc } from "../../../../hooks/useGetDoc";
import { useCollectionDocs } from "../../../../hooks/useCollectionDocs";
import { useSetDoc } from "../../../../hooks/useSetDoc";
import { useAddDocs } from "../../../../hooks/useAddDocs";
import { useDeleteDocs } from "../../../../hooks/useDeleteDocs";
import { useUserDetailsContext } from "../../../../context/UserDetailsContext";

const Resume = () => {
  const { getSingleDocWithQuery, data, isPending, error } = useGetDoc();
  const { documents, isPending: isResumePending, getMultipleDocs } = useCollectionDocs();
  const { firebaseSetDoc, isPending: isUpdating, isConfirmed, error: updateError } = useSetDoc();
  const { addADoc, isPending: isAddPending, error: addError } = useAddDocs();
  const { deleteDocument, isPending: deletePending } = useDeleteDocs();
  const { userData, isPending: isUserPending, id } = useUserDetailsContext();

  const [showModal, setShowModal] = useState(false);
  const [editResume, setEditResume] = useState(null);

  const loadResumes = () => {
    getMultipleDocs({
      _collection: "resumes",
      _query: false,
      orderByField: "createdAt",
      orderByOption: "asc"
    });
  };

  useEffect(() => {
    loadResumes();
  }, []);

  useEffect(() => {
    getSingleDocWithQuery("resumes", false);
  }, [getSingleDocWithQuery]);

  const initialValues = {
    title: "",
    startYear: "",
    endYear: "",
    place: "",
    works: [{ data: "" }]
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    startYear: Yup.string().required("Start year is required"),
    endYear: Yup.string().required("End year is required"),
    place: Yup.string().required("Place is required"),
    works: Yup.array()
      .of(
        Yup.object().shape({
          data: Yup.string().required("Data is required")
        })
      )
      .required("At least one work entry is required")
      .min(1)
  });

  const handleEditResume = (resume) => {
    setEditResume(resume);
    setShowModal(true);
  };

  const handleDeleteResume = async (id) => {
    await deleteDocument("resumes", id);
    loadResumes();
  };

  const formik = useFormik({
    initialValues: {
      resume_area: userData?.resume_area || ""
    },
    validationSchema: Yup.object({
      resume_area: Yup.string().required("Skill area is required")
    }),
    onSubmit: async (values) => {
      await firebaseSetDoc("users", { resume_area: values.resume_area }, id, true);
    }
  });

  return (
    <div className="container mt-5">
      <div>
        <Form onSubmit={formik.handleSubmit} className="mb-4 ">
          <Form.Group>
            <Form.Label>Resume Area</Form.Label>
            <Form.Control
              as="textarea"
              name="resume_area"
              value={formik.values.resume_area}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.resume_area && formik.errors.resume_area}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.resume_area}</Form.Control.Feedback>
          </Form.Group>
          <Button type="submit" disabled={formik.isSubmitting} className="mt-2">
            {formik.isSubmitting ? (
              <Spinner animation="border" size="sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              "Update Resume Area"
            )}
          </Button>
        </Form>
      </div>

      <div className="row my-3">
        <div className="col-12 text-end">
          <Button
            variant="primary mt-3"
            onClick={() => {
              setEditResume(null);
              setShowModal(true);
            }}
          >
            Add New Resume Entry
          </Button>
        </div>
      </div>

      {isResumePending ? (
        <p>Loading...</p>
      ) : (
        <Row>
          {documents && documents.length > 0 ? (
            documents.map((resume) => (
              <Col md={12} key={resume.id}>
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title>{resume.title}</Card.Title>
                    <Card.Text>
                      {resume.startYear} - {resume.endYear}
                    </Card.Text>
                    <Card.Text>{resume.place}</Card.Text>
                    <ul>
                      {resume.works.map((work, i) => (
                        <li key={i}>
                          <strong>{work.data}</strong>
                        </li>
                      ))}
                    </ul>
                    <div className="d-flex justify-content-between">
                      <Button variant="secondary" onClick={() => handleEditResume(resume)}>
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        disabled={deletePending}
                        onClick={() => handleDeleteResume(resume.id)}
                      >
                        Delete
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <p>No resume entries found.</p>
          )}
        </Row>
      )}

      {error && <p className="text-danger">{error}</p>}
      {updateError && <p className="text-danger">{updateError}</p>}
      {addError && <p className="text-danger">{addError}</p>}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{editResume ? "Edit Resume Entry" : "Add New Resume Entry"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={editResume || initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
              if (editResume) {
                await firebaseSetDoc("resumes", values, editResume.id);
              } else {
                await addADoc("resumes", values);
              }
              loadResumes();
              setShowModal(false);
              resetForm();
              setEditResume(null);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.title && errors.title}
                  />
                  <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Start Year</Form.Label>
                  <Form.Control
                    type="text"
                    name="startYear"
                    value={values.startYear}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.startYear && errors.startYear}
                  />
                  <Form.Control.Feedback type="invalid">{errors.startYear}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>End Year</Form.Label>
                  <Form.Control
                    type="text"
                    name="endYear"
                    value={values.endYear}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.endYear && errors.endYear}
                  />
                  <Form.Control.Feedback type="invalid">{errors.endYear}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Place</Form.Label>
                  <Form.Control
                    type="text"
                    name="place"
                    value={values.place}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.place && errors.place}
                  />
                  <Form.Control.Feedback type="invalid">{errors.place}</Form.Control.Feedback>
                </Form.Group>
                <FieldArray
                  name="works"
                  render={(arrayHelpers) => (
                    <div>
                      {values.works.map((work, index) => (
                        <div key={index}>
                          <div className="row align-items-baseline">
                            <div className="col-11 d-flex pe-0">
                              <Form.Group className="mb-3 flex-grow-1 me-2">
                                <Form.Control
                                  type="text"
                                  name={`works.${index}.data`}
                                  value={work.data}
                                  placeholder="Work Data"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isInvalid={
                                    touched.works &&
                                    touched.works[index] &&
                                    errors.works &&
                                    errors.works[index]?.data
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.works && errors.works[index]?.data}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </div>
                            <div className="col-1 px-0 d-flex align-items-center justify-content-center">
                              <Button variant="danger" onClick={() => arrayHelpers.remove(index)}>
                                <i className="bi bi-trash"></i>
                              </Button>
                            </div>
                          </div>
                        </div>
                      ))}
                      <Button
                        className="btn-sm"
                        variant="primary"
                        onClick={() => arrayHelpers.push({ data: "" })}
                      >
                        <i className="bi bi-plus-circle-fill"></i>
                      </Button>
                    </div>
                  )}
                />
                <Button variant="primary mt-3" type="submit" disabled={isAddPending || isUpdating}>
                  {isAddPending || isUpdating ? (
                    <Spinner animation="border" size="sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    "Save"
                  )}
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Resume;
