import React from "react";
import { Header } from "../../components/header/Header";
import About from "./about/About";
import Hero from "./hero/Hero";
import Stats from "./stats/Stats";
import Skills from "./skills/Skills.js";
import Resume from "./resume/Resume.js";
import Portfolio from "./portfolio/Portfolio.js";
import Services from "./services/Services.js";
import Contact from "./contact/Contact.js";

const Home = () => {
  return (
    <>
      <Header />
      <main>
        <Hero />
        <About />
        <Stats />
        <Skills />
        <Resume />
        <Portfolio />
        <Services />
        <Contact />
      </main>
    </>
  );
};

export default Home;
