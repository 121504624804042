import React, { useState, useEffect } from "react";
import { Table, Modal, Button, Form, Spinner } from "react-bootstrap";
import { db } from "../../../../firebase/config";
import { useCollectionDocs } from "../../../../hooks/useCollectionDocs";
import { useSetDoc } from "../../../../hooks/useSetDoc";
import { useDeleteDocs } from "../../../../hooks/useDeleteDocs";
import { doc, writeBatch } from "firebase/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useUserDetailsContext } from "../../../../context/UserDetailsContext";

const Contact = () => {
  const { userData, isPending: isUserPending, id } = useUserDetailsContext();
  const {
    documents: messages_data,
    isPending,
    getMultipleDocs,
    deleteDocument
  } = useCollectionDocs("messages");
  const { firebaseSetDoc } = useSetDoc(); // Hook for updating documents
  const { deleteDocuments } = useDeleteDocs(); // Hook for deleting documents

  const [selectedMessages, setSelectedMessages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState([]);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    getMultipleDocs({
      _collection: `messages`,
      _query: false
    });
  };

  useEffect(() => {
    if (messages_data && Array.isArray(messages_data)) {
      setMessages(messages_data);
    }
  }, [messages_data]);

  const handleDelete = async () => {
    try {
      var batch = writeBatch(db);
      selectedMessages.forEach((msg) => {
        const docRef = doc(db, "messages", msg.id);
        batch.delete(docRef);
      });
      await batch.commit();
      setSelectedMessages([]);
      loadData();
    } catch (error) {
      console.error("Error deleting messages:", error);
    }
  };

  const handleMarkAsRead = async () => {
    var batch = writeBatch(db);
    selectedMessages.forEach((msg) => {
      const docRef = doc(db, "messages", msg.id);
      batch.update(docRef, { read_status: true });
    });

    try {
      await batch.commit();
      setSelectedMessages([]);
      loadData();
    } catch (error) {
      console.error("Error marking messages as read:", error);
    }
  };

  const handleCheckboxChange = (e, message) => {
    if (e.target.checked) {
      setSelectedMessages([...selectedMessages, message]);
    } else {
      setSelectedMessages(selectedMessages.filter((msg) => msg.id !== message.id));
    }
  };

  const handleMarkAllReadMessages = () => {
    if (selectedMessages.length == 0) {
      setSelectedMessages(messages.filter((msg) => msg.read_status !== false));
    } else {
      setSelectedMessages([]);
    }
  };

  const handleRowClick = (message) => {
    setSelectedMessage(message);
    setShowModal(true);

    // Mark message as read when opening modal
    if (!message.read_status) {
      firebaseSetDoc("messages", { ...message, read_status: true }, message.id);
    }
    loadData();
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedMessage(null);
  };

  const formik = useFormik({
    initialValues: {
      contact_area: userData?.contact_area || ""
    },
    validationSchema: Yup.object({
      contact_area: Yup.string().required("Contact area is required")
    }),
    onSubmit: async (values) => {
      await firebaseSetDoc("users", { contact_area: values.contact_area }, id, true);
    }
  });

  return (
    <div className="container mt-4">
      <div>
        <Form onSubmit={formik.handleSubmit} className="mb-4 ">
          <Form.Group>
            <Form.Label>Contact Area</Form.Label>
            <Form.Control
              as="textarea"
              name="contact_area"
              value={formik.values.contact_area}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.contact_area && formik.errors.contact_area}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.contact_area}</Form.Control.Feedback>
          </Form.Group>
          <Button type="submit" disabled={formik.isSubmitting} className="mt-2">
            {formik.isSubmitting ? (
              <Spinner animation="border" size="sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              "Update Contact Area"
            )}
          </Button>
        </Form>
      </div>

      <h2>Contact Messages</h2>
      <>
        <div className="mb-3 btn-group" role="group">
          <Button size="sm" variant="danger" onClick={handleDelete}>
            Delete
          </Button>{" "}
          <Button size="sm" variant="success" onClick={handleMarkAsRead}>
            Mark as Read
          </Button>
          <Button size="sm" variant="primary" onClick={handleMarkAllReadMessages}>
            {selectedMessage.length == 0 ? "Mark" : "Unmark"} Old Messages
          </Button>
        </div>
        <Table bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Subject</th>
            </tr>
          </thead>
          <tbody>
            {messages &&
              Array.isArray(messages) &&
              messages.length > 0 &&
              messages.map((message) => {
                const rowStyle = { backgroundColor: message.read_status ? "" : "#f0f0f0" };
                const cellStyle = { ...rowStyle, cursor: "pointer" };

                return (
                  <tr key={message.id}>
                    <td style={rowStyle}>
                      <Form.Check
                        type="checkbox"
                        checked={selectedMessages.some((msg) => msg.id === message.id)}
                        onChange={(e) => handleCheckboxChange(e, message)}
                      />
                    </td>
                    <td onClick={() => handleRowClick(message)} style={cellStyle}>
                      {message.name}
                    </td>
                    <td onClick={() => handleRowClick(message)} style={cellStyle}>
                      {message.email}
                    </td>
                    <td onClick={() => handleRowClick(message)} style={cellStyle}>
                      {message.subject}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </>

      {/* Modal for displaying message details */}
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Message Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedMessage && (
            <>
              <p>
                <strong>Name:</strong> {selectedMessage.name}
              </p>
              <p>
                <strong>Email:</strong> {selectedMessage.email}
              </p>
              <p>
                <strong>Subject:</strong> {selectedMessage.subject}
              </p>
              <p>
                <strong>Message:</strong> {selectedMessage.message}
              </p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          {!selectedMessage?.read_status && (
            <Button
              variant="primary"
              onClick={() => {
                firebaseSetDoc("messages", { ...selectedMessage, read_status: true }, selectedMessage.id);
                closeModal();
              }}
            >
              Mark as Read
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Contact;
