import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, Card, Spinner } from "react-bootstrap";
import { Formik, FieldArray, useFormik } from "formik";
import * as Yup from "yup";
import { useCollectionDocs } from "../../../../hooks/useCollectionDocs";
import { useSetDoc } from "../../../../hooks/useSetDoc";
import { useAddDocs } from "../../../../hooks/useAddDocs";
import { useDeleteDocs } from "../../../../hooks/useDeleteDocs";
import { useUserDetailsContext } from "../../../../context/UserDetailsContext";

const Skills = () => {
  const { documents: skills, isPending: isSkillsLoading, getMultipleDocs } = useCollectionDocs();
  const { firebaseSetDoc, isPending: isUpdating, isConfirmed, error } = useSetDoc();
  const { addADoc, isPending: isAddPending, error: addError } = useAddDocs();
  const { deleteDocument, isPending: deletePending } = useDeleteDocs();
  const { userData, isPending: isUserPending, id } = useUserDetailsContext();

  const [showModal, setShowModal] = useState(false);
  const [editSkill, setEditSkill] = useState(null);

  const loadMore = () => {
    getMultipleDocs({
      _collection: `skills`,
      _query: false,
      orderByField: "createdAt",
      orderByOption: "asc"
    });
  };

  useEffect(() => {
    loadMore();
  }, []);

  const initialValues = {
    fieldName: "",
    skills: [{ name: "", tag: "" }],
    description: ""
  };

  const validationSchema = Yup.object({
    fieldName: Yup.string().required("Field name is required"),
    skills: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required("Skill name is required"),
          tag: Yup.string().required("Tag is required")
        })
      )
      .required("At least one skill is required")
      .min(1),
    description: Yup.string()
  });

  const handleDeleteSkill = async (id) => {
    await deleteDocument("skills", id);
    loadMore();
  };

  const handleEditSkill = (skill) => {
    setEditSkill(skill);
    setShowModal(true);
  };

  const formik = useFormik({
    initialValues: {
      skillArea: userData?.skill_area || ""
    },
    validationSchema: Yup.object({
      skillArea: Yup.string().required("Skill area is required")
    }),
    onSubmit: async (values) => {
      await firebaseSetDoc("users", { skill_area: values.skillArea }, id, true);
    }
  });

  return (
    <div className="container mt-5">
      <div>
        <Form onSubmit={formik.handleSubmit} className="mb-4 ">
          <Form.Group>
            <Form.Label>Skill Area</Form.Label>
            <Form.Control
              as="textarea"
              name="skillArea"
              value={formik.values.skillArea}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.skillArea && formik.errors.skillArea}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.skillArea}</Form.Control.Feedback>
          </Form.Group>
          <Button type="submit" disabled={formik.isSubmitting} className="mt-2">
            {formik.isSubmitting ? (
              <Spinner animation="border" size="sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              "Update Skill Area"
            )}
          </Button>
        </Form>
      </div>

      <div className="row my-3">
        <div className="col-12 text-end white-space">
          <Button
            variant="primary mt-3"
            onClick={() => {
              setEditSkill(null);
              setShowModal(true);
            }}
          >
            Add New Skill
          </Button>
        </div>
      </div>

      {isSkillsLoading ? (
        <p>Loading...</p>
      ) : (
        <Row>
          {skills && Array.isArray(skills) && skills.length > 0 && (
            <>
              {skills.map((skill) => (
                <Col md={12} key={skill.id}>
                  <Card className="mb-3">
                    <Card.Body>
                      <Card.Title>{skill.fieldName}</Card.Title>
                      {skill.skills && Array.isArray(skill.skills) && skill.skills.length > 0 && (
                        <ul>
                          {skill.skills.map((s, i) => (
                            <li key={i}>
                              <strong>{s.name}</strong> - {s.tag}
                            </li>
                          ))}
                        </ul>
                      )}
                      <Card.Text>{skill.description}</Card.Text>
                      <div className="d-flex justify-content-between">
                        <Button variant="secondary" onClick={() => handleEditSkill(skill)}>
                          Edit
                        </Button>
                        <Button
                          variant="danger"
                          disabled={deletePending ? true : false}
                          onClick={() => handleDeleteSkill(skill.id)}
                        >
                          Delete
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </>
          )}
        </Row>
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{editSkill ? "Edit Skill" : "Add New Skill"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={editSkill || initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
              if (editSkill) {
                await firebaseSetDoc("skills", values, editSkill.id);
                loadMore();
              } else {
                await addADoc("skills", values);
                loadMore();
              }
              setShowModal(false);
              resetForm();
              setEditSkill(null);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setValues }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Field Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="fieldName"
                    value={values.fieldName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.fieldName && errors.fieldName}
                  />
                  <Form.Control.Feedback type="invalid">{errors.fieldName}</Form.Control.Feedback>
                </Form.Group>
                <FieldArray
                  name="skills"
                  render={(arrayHelpers) => (
                    <div>
                      {values.skills.map((skill, index) => (
                        <div key={index}>
                          <div className="row align-items-baseline">
                            <div className="col-11 d-flex pe-0">
                              <Form.Group className="mb-3 flex-grow-1 me-2">
                                <Form.Control
                                  type="text"
                                  name={`skills.${index}.name`}
                                  value={skill.name}
                                  placeholder="Skill Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isInvalid={
                                    touched.skills &&
                                    touched.skills[index] &&
                                    errors.skills &&
                                    errors.skills[index]?.name
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.skills && errors.skills[index]?.name}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-3 flex-grow-1 me-2">
                                <Form.Control
                                  type="text"
                                  name={`skills.${index}.tag`}
                                  value={skill.tag}
                                  placeholder="Tag"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isInvalid={
                                    touched.skills &&
                                    touched.skills[index] &&
                                    errors.skills &&
                                    errors.skills[index]?.tag
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.skills && errors.skills[index]?.tag}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </div>
                            <div className="col-1 px-0 d-flex align-items-center justify-content-center">
                              <Button variant="danger" onClick={() => arrayHelpers.remove(index)}>
                                <i className="bi bi-trash"></i>
                              </Button>
                            </div>
                          </div>
                        </div>
                      ))}
                      <Button
                        className="btn-sm"
                        variant="primary"
                        onClick={() => arrayHelpers.push({ name: "", tag: "" })}
                      >
                        <i className="bi bi-plus-circle-fill"></i>
                      </Button>
                    </div>
                  )}
                />
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.description && errors.description}
                  />
                  <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                </Form.Group>
                <Button variant="primary" type="submit" disabled={isAddPending || isUpdating}>
                  {isAddPending || isUpdating ? (
                    <Spinner animation="border" size="sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    "Save"
                  )}
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Skills;
