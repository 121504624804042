import React, { useEffect } from "react";
import Styles from "./Services.module.css";
import { useGetDoc } from "../../../hooks/useGetDoc";
import { useCollectionDocs } from "../../../hooks/useCollectionDocs";
import { documentId } from "firebase/firestore";
import { Link } from "react-router-dom";

const Services = () => {
  const { documents, isPending: isServicePending, getMultipleDocs } = useCollectionDocs();
  const { getSingleDocWithQuery, data, isPending, error } = useGetDoc();
  useEffect(() => {
    getSingleDocWithQuery("users", [[documentId(), "==", process.env.REACT_APP_ADMIN_ID]]);
  }, []);
  const loadService = () => {
    getMultipleDocs({
      _collection: `services`,
      _query: false,
      orderByField: "createdAt",
      orderByOption: "asc"
    });
  };

  useEffect(() => {
    loadService();
  }, []);

  return (
    <section id="services" className={`${Styles.services} section`}>
      <div className="container section-title" data-aos="fade-up">
        <h2>Services</h2>
        <p>{data && Array.isArray(data) && data.length > 0 && data[0].service_area}</p>
      </div>

      <div className="container">
        <div className="row gy-4">
          {documents && Array.isArray(documents) && documents.length > 0 && (
            <>
              {documents.map((item, i) => {
                return (
                  <div
                    key={i}
                    className={`col-lg-4 col-md-6 ${Styles.serviceItem} d-flex`}
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div className={`${Styles.icon} flex-shrink-0`}>
                      <i className={item.iconClass}></i>
                    </div>
                    <div>
                      <h4 className={Styles.title}>
                        <a href="/#contact" className="stretched-link">
                          {item.name}
                        </a>
                      </h4>
                      <p className={Styles.description}>{item.details}</p>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Services;
