import React, { useEffect } from "react";
import Styles from "./Stats.module.css";
import { Container } from "react-bootstrap";
import PureCounter from "@srexi/purecounterjs";
import { useGetDoc } from "../../../hooks/useGetDoc";
import { documentId } from "firebase/firestore";

const Stats = () => {
  const { getSingleDocWithQuery, data, isPending, error } = useGetDoc();

  useEffect(() => {
    getSingleDocWithQuery("users", [[documentId(), "==", process.env.REACT_APP_ADMIN_ID]]);
  }, []);

  useEffect(() => {
    if (data) {
      new PureCounter();
    }
  }, [data]);

  return (
    <section id="stats" className={`${Styles.stats} section`}>
      {data && Array.isArray(data) && data.length > 0 && (
        <Container className="container" data-aos="fade-up" data-aos-delay="100">
          <div className="row gy-4 justify-content-center">
            <div className={Styles.item}>
              <div className={Styles.statsItem}>
                <div className="d-flex justify-content-center">
                  <i className="bi bi-person-workspace"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="3"
                    data-purecounter-duration="1"
                    className={`${Styles.purecounter} purecounter`}
                  ></span>
                </div>
                <p>
                  <strong>Happy Organizations</strong>
                </p>
              </div>
            </div>
            <div className={Styles.item}>
              <div className={Styles.statsItem}>
                <div className="d-flex justify-content-center position-relative">
                  <i className="bi bi-person-workspace"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end={data[0].experience_years}
                    data-purecounter-duration="1"
                    className={`${Styles.purecounter} purecounter`}
                  ></span>
                  <span className={Styles.ex_txt_1}>Years +</span>
                </div>
                <p>
                  <strong>Professional Experience</strong>
                </p>
              </div>
            </div>

            <div className={Styles.item}>
              <div className={Styles.statsItem}>
                <div className="d-flex justify-content-center position-relative">
                  <i className="bi bi-kanban"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end={data[0].projects}
                    data-purecounter-duration="1"
                    className={`${Styles.purecounter} purecounter`}
                  ></span>
                  <span className={Styles.ex_txt_2}>+</span>
                </div>
                <p>
                  <strong>Personal and Private Projects</strong>
                </p>
              </div>
            </div>

            <div className={Styles.item}>
              <div className={Styles.statsItem}>
                <div className="d-flex justify-content-center">
                  <i className="bi bi-file-earmark-zip-fill"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end={data[0].published_libraries}
                    data-purecounter-duration="1"
                    className={`${Styles.purecounter} purecounter`}
                  ></span>
                </div>
                <p>
                  <strong>Published Libraries</strong>
                </p>
              </div>
            </div>

            <div className={Styles.item}>
              <div className={Styles.statsItem}>
                <div className="d-flex justify-content-center">
                  <i className="bi bi-journal-richtext"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end={data[0].published_paper}
                    data-purecounter-duration="1"
                    className={`${Styles.purecounter} purecounter`}
                  ></span>
                </div>
                <p>
                  <strong>
                    Published<br></br> Paper
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </Container>
      )}
    </section>
  );
};

export default Stats;
