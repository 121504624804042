import React, { useRef, useState } from "react";

import ReactCrop from "react-image-crop";
import { Button, Modal } from "react-bootstrap";

import getCroppedImgPercent, { centerAspectCrop } from "../../utils/CropperPercent";

import "react-image-crop/dist/ReactCrop.css";

function CropModal({ show, setShow, setImage, imgSrc, aspect, localStorageVar }) {
  const imgRef = useRef(null);
  const [crop, setCrop] = useState(undefined);
  const [completedCrop, setCompletedCrop] = useState();
  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, aspect));
  };

  const onDownloadCropClick = async () => {
    await getCroppedImgPercent(imgSrc, crop).then((url) => {
      setImage(localStorageVar, url);
      localStorage.setItem(localStorageVar, url);
      setShow(false);
    });
  };

  return (
    <Modal
      onHide={() => setShow(false)}
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!!imgSrc && (
          <div className="d-flex justify-content-center align-items-center">
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
            >
              <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} className="w-100" />
            </ReactCrop>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShow(false)}>Close</Button>
        {!!completedCrop && (
          <Button
            onClick={() => {
              onDownloadCropClick();
            }}
          >
            Crop
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default CropModal;
