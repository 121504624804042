import React, { useEffect } from "react";
import Styles from "./Skills.module.css";
import { Container, Row } from "react-bootstrap";
import PureCounter from "@srexi/purecounterjs";
import { Waypoint } from "react-waypoint";
import { useGetDoc } from "../../../hooks/useGetDoc";
import { documentId } from "firebase/firestore";
import { useCollectionDocs } from "../../../hooks/useCollectionDocs";

const Skills = () => {
  const { documents, isPending: isSkillPending, getMultipleDocs } = useCollectionDocs();
  const { getSingleDocWithQuery, data, isPending, error } = useGetDoc();
  useEffect(() => {
    getSingleDocWithQuery("users", [[documentId(), "==", process.env.REACT_APP_ADMIN_ID]]);
  }, []);

  const loadSkill = () => {
    getMultipleDocs({
      _collection: `skills`,
      _query: false,
      orderByField: "createdAt",
      orderByOption: "asc"
    });
  };

  useEffect(() => {
    loadSkill();
  }, []);

  const handleWaypointEnter = () => {
    const progressBars = document.querySelectorAll("." + Styles.progressBar);
    progressBars.forEach((bar) => {
      bar.style.width = bar.getAttribute("aria-valuenow") + "%";
    });
  };

  return (
    <section id="skills" className={`${Styles.skills} section`}>
      <Waypoint onEnter={handleWaypointEnter}>
        <div className="container" data-aos="fade-up">
          <div className={`section-title ${Styles.padding_less}`}>
            <h2>Skills</h2>
            {data && Array.isArray(data) && data.length > 0 && <p>{data[0].skill_area}</p>}
          </div>

          <div className="row">
            <div className="col-lg-12">
              <ul>
                {documents && Array.isArray(documents) && documents.length > 0 && (
                  <>
                    {documents.map((skill_item, i) => {
                      return (
                        <li key={i}>
                          <div className={Styles.Skill_Container} data-aos="fade-up" data-aos-delay="100">
                            <div className={Styles.skill_name}>
                              <i className="bi bi-chevron-right"></i> <strong>{skill_item.fieldName}:</strong>
                            </div>
                            <div className={Styles.skill_items}>
                              {skill_item.skills &&
                                Array.isArray(skill_item.skills) &&
                                skill_item.skills.length > 0 &&
                                skill_item.skills.map((skill, j) => {
                                  return (
                                    <a key={j} href="#">
                                      {skill.name}
                                    </a>
                                  );
                                })}
                            </div>
                          </div>
                          <div>{skill_item.description && <p>{skill_item.description}</p>}</div>
                        </li>
                      );
                    })}
                  </>
                )}
              </ul>
            </div>
          </div>
          {/* <div className="row justify-content-center skills-content skills-animation">
            <div className="col-11 col-lg-6">
              <div className={Styles.progress}>
                <span className={Styles.skill}>
                  <span>HTML</span> <i className={Styles.val}>100%</i>
                </span>
                <div className={Styles.skill}>
                  <div
                    className={Styles.progressBar}
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div className={Styles.progress}>
                <span className={Styles.skill}>
                  <span>CSS</span> <i className={Styles.val}>90%</i>
                </span>
                <div className={Styles.skill}>
                  <div
                    className={Styles.progressBar}
                    role="progressbar"
                    aria-valuenow="90"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div className={Styles.progress}>
                <span className={Styles.skill}>
                  <span>JavaScript</span> <i className={Styles.val}>75%</i>
                </span>
                <div className={Styles.skill}>
                  <div
                    className={Styles.progressBar}
                    role="progressbar"
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>

            <div className="col-11 col-lg-6">
              <div className={Styles.progress}>
                <span className={Styles.skill}>
                  <span>PHP</span> <i className={Styles.val}>80%</i>
                </span>
                <div className={Styles.progressBarWrap}>
                  <div
                    className={Styles.progressBar}
                    role="progressbar"
                    aria-valuenow="80"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div className={Styles.progress}>
                <span className={Styles.skill}>
                  <span>WordPress/CMS</span> <i className={Styles.val}>90%</i>
                </span>
                <div className={Styles.skill}>
                  <div
                    className={Styles.progressBar}
                    role="progressbar"
                    aria-valuenow="90"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div className={Styles.progress}>
                <span className={Styles.skill}>
                  <span>Photoshop</span> <i className={Styles.val}>55%</i>
                </span>
                <div className={Styles.skill}>
                  <div
                    className={Styles.progressBar}
                    role="progressbar"
                    aria-valuenow="55"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </Waypoint>
    </section>
  );
};

export default Skills;
