import React, { useEffect, useState } from "react";
import Styles from "./About.module.css";
import { Container, Row } from "react-bootstrap";
import { useGetDoc } from "../../../hooks/useGetDoc";
import { documentId } from "firebase/firestore";
import { formatDateString, getYearDifference } from "../../../utils/Utils";

const About = () => {
  const { getSingleDocWithQuery, data, isPending, error } = useGetDoc();
  const [userData, setUSerData] = useState(null);

  useEffect(() => {
    getSingleDocWithQuery("users", [[documentId(), "==", process.env.REACT_APP_ADMIN_ID]]);
  }, []);

  return (
    <section id="about" className={`${Styles.about} section`}>
      {data && Array.isArray(data) && data.length > 0 && (
        <>
          <Container className="section-title" data-aos="fade-up">
            <h2>About</h2>
            <p>{data[0].intro}</p>
          </Container>

          <Container data-aos="fade-up" data-aos-delay="100">
            <Row className="gy-4 justify-content-center">
              <div className="col-lg-4">
                <img src={data[0].profileImage} className="img-fluid" alt="" />
              </div>
              <div className={`col-lg-8 ${Styles.content}`}>
                <h2>{data[0].title}</h2>
                <p className="fst-italic py-3">{data[0].titleIntro}</p>
                <div className="row">
                  <div className="col-lg-5">
                    <ul>
                      <li>
                        <i className="bi bi-chevron-right"></i> <strong>Birthday:</strong>{" "}
                        <span>{formatDateString(data[0].birthday)}</span>
                      </li>
                      <li>
                        <i className="bi bi-chevron-right"></i> <strong>Website:</strong>{" "}
                        <span>
                          <a href={data[0].website} target="_blank">
                            {data[0].website.replace("https://", "")}
                          </a>
                        </span>
                      </li>
                      <li>
                        <i className="bi bi-chevron-right"></i> <strong>Phone:</strong>{" "}
                        <span>
                          <a href={`tel: ${data[0].phone}`}>{data[0].phone}</a>
                        </span>
                      </li>
                      <li>
                        <i className="bi bi-chevron-right"></i> <strong>City:</strong>{" "}
                        <span>{data[0].city}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-7 ps-sm-0">
                    <ul>
                      <li>
                        <i className="bi bi-chevron-right"></i> <strong>Age:</strong>{" "}
                        <span>{getYearDifference(data[0].birthday)}</span>
                      </li>
                      <li>
                        <i className="bi bi-chevron-right"></i> <strong>Degree:</strong>{" "}
                        <span>{data[0].degree}</span>
                      </li>
                      <li>
                        <i className="bi bi-chevron-right"></i> <strong>Email:</strong>{" "}
                        <span>
                          <a href={`mailto: ${data[0].email}`}>{data[0].email}</a>
                        </span>
                      </li>
                      <li>
                        <i className="bi bi-chevron-right"></i> <strong>Freelance:</strong>{" "}
                        <span>{data[0].freelance_status}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <p className="py-3">{data[0].conclusion}</p>
              </div>
            </Row>
          </Container>
        </>
      )}
    </section>
  );
};

export default About;
