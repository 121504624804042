import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useUserDetailsContext } from "../../../../context/UserDetailsContext";
import CropModal from "../../../../components/ImageCrop/CropModal";
import { getDownloadURL, ref, uploadBytes, uploadString } from "firebase/storage";
import { storage } from "../../../../firebase/config";
import { getRandomString } from "../../../../utils/Utils";
import { useSetDoc } from "../../../../hooks/useSetDoc";
import { Spinner } from "react-bootstrap";
import { useToast } from "../../../../hooks/useToast";

const About = () => {
  const { userData, isPending, id } = useUserDetailsContext();
  const [showSmallImageCropModal, setShowSmallImageCropModal] = useState(false);
  const [showProfileImageCropModal, setShowProfileImageCropModal] = useState(false);
  const [smallImgSrc, setSmallImgSrc] = useState("");
  const [profileImgSrc, setProfileImgSrc] = useState("");
  const [smallImageUrl, setSmallImageUrl] = useState("");
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [pdfFileSrc, setPdfFileSrc] = useState("");

  const { firebaseSetDoc, isPending: isUpdating, isConfirmed, error } = useSetDoc();
  const toast = useToast();

  useEffect(() => {
    setProfileImageUrl(userData.profileImage);
    setSmallImageUrl(userData.smallImage);
  }, [userData]);

  const uploadToStorage = async (url) => {
    const imgRef = ref(storage, `image/settings/${getRandomString(23)}`);
    await uploadString(imgRef, url, "data_url");
    return await getDownloadURL(imgRef);
  };

  const formik = useFormik({
    initialValues: {
      fname: userData?.fname || "",
      lname: userData?.lname || "",
      intro: userData?.intro || "",
      title: userData?.title || "",
      titleIntro: userData?.titleIntro || "",
      birthday: userData?.birthday || "",
      website: userData?.website || "",
      phone: userData?.phone || "",
      city: userData?.city || "",
      address: userData?.address || "",
      map: userData?.map || "",
      degree: userData?.degree || "",
      email: userData?.email || "",
      freelance_status: userData?.freelance_status || "Available",
      smallImage: "",
      profileImage: "",
      conclusion: userData?.conclusion || "",
      resume_url: userData?.resume_url || ""
    },
    validationSchema: Yup.object({
      fname: Yup.string(),
      lname: Yup.string(),
      intro: Yup.string(),
      title: Yup.string(),
      titleIntro: Yup.string(),
      birthday: Yup.string(),
      website: Yup.string(),
      phone: Yup.string(),
      city: Yup.string(),
      address: Yup.string(),
      map: Yup.string(),
      degree: Yup.string(),
      email: Yup.string().email("Invalid email address"),
      freelance_status: Yup.string(),
      conclusion: Yup.string(),
      resume_url: Yup.mixed().nullable()
    }),
    onSubmit: async (values) => {
      let smallImageUrl = null;
      let profileImageUrl = null;

      if (smallImgSrc) {
        smallImageUrl = await uploadToStorage(smallImgSrc);
      }

      if (profileImgSrc) {
        profileImageUrl = await uploadToStorage(profileImgSrc);
      }

      let resumeUrl = null;

      if (pdfFileSrc instanceof File) {
        const pdfRef = ref(storage, `MD Aidid Alam.pdf`);
        await uploadBytes(pdfRef, pdfFileSrc); // Use uploadBytes for file objects
        resumeUrl = await getDownloadURL(pdfRef);
      }

      const dataToSubmit = { ...values };
      delete dataToSubmit.profileImage;
      delete dataToSubmit.smallImage;

      if (smallImageUrl) {
        dataToSubmit.smallImage = smallImageUrl;
      }

      if (profileImageUrl) {
        dataToSubmit.profileImage = profileImageUrl;
      }

      if (resumeUrl) {
        dataToSubmit.resume_url = resumeUrl;
      }

      await firebaseSetDoc("users", dataToSubmit, id, true);
      toast("success", "Updated");
    }
  });

  const cropProfileImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setShowProfileImageCropModal(true);
      const reader = new FileReader();
      reader.addEventListener("load", () => setProfileImgSrc(reader.result?.toString() || ""));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const cropSmallProfileImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setShowSmallImageCropModal(true);
      const reader = new FileReader();
      reader.addEventListener("load", () => setSmallImgSrc(reader.result?.toString() || ""));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div className="container mt-5">
      <h1>About</h1>
      {isPending ? (
        <p>Loading...</p>
      ) : (
        <form onSubmit={formik.handleSubmit} className="needs-validation" noValidate>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="fname" className="form-label">
                  First Name
                </label>
                <input
                  id="fname"
                  name="fname"
                  type="text"
                  className={`form-control ${
                    formik.touched.fname && formik.errors.fname ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  value={formik.values.fname}
                />
                {formik.touched.fname && formik.errors.fname ? (
                  <div className="invalid-feedback">{formik.errors.fname}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="lname" className="form-label">
                  Last Name
                </label>
                <input
                  id="lname"
                  name="lname"
                  type="text"
                  className={`form-control ${
                    formik.touched.lname && formik.errors.lname ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  value={formik.values.lname}
                />
                {formik.touched.lname && formik.errors.lname ? (
                  <div className="invalid-feedback">{formik.errors.lname}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="intro" className="form-label">
                  Intro
                </label>
                <textarea
                  id="intro"
                  name="intro"
                  className={`form-control ${
                    formik.touched.intro && formik.errors.intro ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  value={formik.values.intro}
                />
                {formik.touched.intro && formik.errors.intro ? (
                  <div className="invalid-feedback">{formik.errors.intro}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Title
                </label>
                <input
                  id="title"
                  name="title"
                  type="text"
                  className={`form-control ${
                    formik.touched.title && formik.errors.title ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  value={formik.values.title}
                />
                {formik.touched.title && formik.errors.title ? (
                  <div className="invalid-feedback">{formik.errors.title}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="titleIntro" className="form-label">
                  Title Intro
                </label>
                <textarea
                  id="titleIntro"
                  name="titleIntro"
                  className={`form-control ${
                    formik.touched.titleIntro && formik.errors.titleIntro ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  value={formik.values.titleIntro}
                />
                {formik.touched.titleIntro && formik.errors.titleIntro ? (
                  <div className="invalid-feedback">{formik.errors.titleIntro}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="birthday" className="form-label">
                  Birthday
                </label>
                <input
                  id="birthday"
                  name="birthday"
                  type="date"
                  className={`form-control ${
                    formik.touched.birthday && formik.errors.birthday ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  value={formik.values.birthday}
                />
                {formik.touched.birthday && formik.errors.birthday ? (
                  <div className="invalid-feedback">{formik.errors.birthday}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone
                </label>
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  className={`form-control ${
                    formik.touched.phone && formik.errors.phone ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="invalid-feedback">{formik.errors.phone}</div>
                ) : null}
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="city" className="form-label">
                  City
                </label>
                <input
                  id="city"
                  name="city"
                  type="text"
                  className={`form-control ${formik.touched.city && formik.errors.city ? "is-invalid" : ""}`}
                  onChange={formik.handleChange}
                  value={formik.values.city}
                />
                {formik.touched.city && formik.errors.city ? (
                  <div className="invalid-feedback">{formik.errors.city}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="address" className="form-label">
                  Address
                </label>
                <input
                  id="address"
                  name="address"
                  type="text"
                  className={`form-control ${
                    formik.touched.address && formik.errors.address ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  value={formik.values.address}
                />
                {formik.touched.address && formik.errors.address ? (
                  <div className="invalid-feedback">{formik.errors.address}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="map" className="form-label">
                  Map
                </label>
                <input
                  id="map"
                  name="map"
                  type="text"
                  className={`form-control ${formik.touched.map && formik.errors.map ? "is-invalid" : ""}`}
                  onChange={formik.handleChange}
                  value={formik.values.map}
                />
                {formik.touched.map && formik.errors.map ? (
                  <div className="invalid-feedback">{formik.errors.map}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="degree" className="form-label">
                  Degree
                </label>
                <input
                  id="degree"
                  name="degree"
                  type="text"
                  className={`form-control ${
                    formik.touched.degree && formik.errors.degree ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  value={formik.values.degree}
                />
                {formik.touched.degree && formik.errors.degree ? (
                  <div className="invalid-feedback">{formik.errors.degree}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  className={`form-control ${
                    formik.touched.email && formik.errors.email ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="invalid-feedback">{formik.errors.email}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="freelance_status" className="form-label">
                  Freelance
                </label>
                <select
                  id="freelance_status"
                  name="freelance_status"
                  className={`form-select ${
                    formik.touched.freelance_status && formik.errors.freelance_status ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  value={formik.values.freelance_status}
                >
                  <option value="Available">Available</option>
                  <option value="Unavailable">Unavailable</option>
                </select>
                {formik.touched.freelance_status && formik.errors.freelance_status ? (
                  <div className="invalid-feedback">{formik.errors.freelance_status}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="website" className="form-label">
                  Website
                </label>
                <input
                  id="website"
                  name="website"
                  type="text"
                  className={`form-control ${
                    formik.touched.website && formik.errors.website ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  value={formik.values.website}
                />
                {formik.touched.website && formik.errors.website ? (
                  <div className="invalid-feedback">{formik.errors.website}</div>
                ) : null}
              </div>
            </div>

            <div className="col-md-6">
              {smallImageUrl && <img src={smallImageUrl} alt="Small" width={200} className=" mt-2" />}
              <div className="mb-3">
                <label htmlFor="smallImage" className="form-label">
                  Small Image
                </label>
                <input
                  id="smallImage"
                  accept="images/*"
                  type="file"
                  className="form-control"
                  onChange={(event) => {
                    cropSmallProfileImage(event);
                  }}
                />
              </div>
            </div>

            <div className="col-md-6">
              {profileImageUrl && <img src={profileImageUrl} alt="Profile" width={200} className=" mt-2" />}

              <div className="mb-3">
                <label htmlFor="profileImage" className="form-label">
                  Profile Image
                </label>
                <input
                  id="profileImage"
                  accept="images/*"
                  type="file"
                  className="form-control"
                  onChange={(event) => {
                    cropProfileImage(event);
                  }}
                />
              </div>
            </div>

            <div className="col-12">
              <div className="mb-3">
                <label htmlFor="conclusion" className="form-label">
                  Conclusion
                </label>
                <textarea
                  id="conclusion"
                  name="conclusion"
                  className={`form-control ${
                    formik.touched.conclusion && formik.errors.conclusion ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  value={formik.values.conclusion}
                />
                {formik.touched.conclusion && formik.errors.conclusion ? (
                  <div className="invalid-feedback">{formik.errors.conclusion}</div>
                ) : null}
              </div>
            </div>

            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="resume" className="form-label">
                  Resume (PDF only)
                </label>
                <input
                  id="resume"
                  accept=".pdf"
                  type="file"
                  className="form-control"
                  onChange={(event) => {
                    if (event.target.files && event.target.files.length > 0) {
                      setPdfFileSrc(event.target.files[0]);
                    }
                  }}
                />
              </div>
            </div>

            <div className="col-12 text-center mb-5">
              <button type="submit" style={{ width: "200px" }} className="btn btn-success btn-lg">
                {isUpdating ? (
                  <Spinner animation="border" size="sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </form>
      )}

      <CropModal
        show={showSmallImageCropModal}
        setShow={setShowSmallImageCropModal}
        setImage={(localStorageVar, url) => {
          setSmallImgSrc(url);
          setSmallImageUrl(url);
        }}
        imgSrc={smallImgSrc}
        aspect={1 / 1}
        localStorageVar="smallImage"
      />

      <CropModal
        show={showProfileImageCropModal}
        setShow={setShowProfileImageCropModal}
        setImage={(_, url) => {
          setProfileImgSrc(url);
          setProfileImageUrl(url);
        }}
        imgSrc={profileImgSrc}
        aspect={1 / 1}
        localStorageVar="profileImage"
      />
    </div>
  );
};

export default About;
