import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { initializeFirestore } from "firebase/firestore";
// import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const firebaseErrors = {
  "auth/user-not-found": "No user found with this email address.",
  "auth/wrong-password": "Unable to log in.",
  "auth/email-already-in-use": "The registration information is already in use or cannot be used.",
  "auth/email-already-exists": "The registration information is already in use or cannot be used.",
  "auth/expired-action-code": "The URL is invalid or has already been used.",
  "auth/invalid-action-code": "The URL is invalid or has already been used.",
  "auth/network-request-failed": "Please check your network connection.",
  "auth/requires-recent-login": "For security reasons, please log in again and try.",
  "auth/user-token-expired": "The session has expired. Please log in again and try.",
  "auth/too-many-requests":
    "The account is locked. Please change your password or wait for a while before trying again.",
  "auth/invalid-email": "Invalid email address.",
  "auth/invalid-new-email": "Invalid new email address.",
  "auth/user-disabled": "The account has been disabled."
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

//init firebase Auth
const auth = getAuth();
const db = initializeFirestore(app, { useFetchStreams: false });
const storage = getStorage();

// const functions = getFunctions(app);
// connectFunctionsEmulator(functions, "localhost", 5001);
// connectFirestoreEmulator(db, "localhost", 5004);

export { auth, db, firebaseErrors, storage };
