import React, { useEffect, useRef, useState } from "react";
import Styles from "./Hero.module.css";
import { Player } from "@lottiefiles/react-lottie-player";
import { Container } from "react-bootstrap";
import Typed from "typed.js";
import { Link } from "react-router-dom";
import { useCollectionDocs } from "../../../hooks/useCollectionDocs";
import { useGetDoc } from "../../../hooks/useGetDoc";
import { documentId } from "firebase/firestore";

const Hero = () => {
  const typedRef = useRef(null);
  const [article, setArticle] = useState("a");
  const [typedDone, setTypedDone] = useState(true);
  const [readyToHide, setReadyToHide] = useState(false);
  const { documents, isPending, getMultipleDocs } = useCollectionDocs();
  const { getSingleDocWithQuery, data, isPending: isPending2, error } = useGetDoc();
  const [userData, setUserData] = useState({});

  const {
    documents: socialLinks,
    isPending: isSocialPending,
    getMultipleDocs: getSocialLinks
  } = useCollectionDocs();
  const [typedStrings, setTypedStrings] = useState([]);

  useEffect(() => {
    getSingleDocWithQuery("users", [[documentId(), "==", process.env.REACT_APP_ADMIN_ID]]);
  }, []);

  useEffect(() => {
    if (data && Array.isArray(data) && data.length > 0) {
      setUserData(data[0]);
    }
  }, [data]);

  useEffect(() => {
    if (typedRef.current && typedStrings.length > 0) {
      const dataStrings = typedStrings.map((str) => str.replace(/&/g, "&#38;"));
      const typed = new Typed(typedRef.current, {
        strings: dataStrings,
        loop: false,
        typeSpeed: 30,
        backSpeed: 30,
        backDelay: 2000,
        preStringTyped: (arrayPos) => {
          const currentString = typedStrings[arrayPos];
          setArticle(startsWithVowel(currentString) ? "an" : "a");
        },
        onComplete: () => {
          setTimeout(() => {
            setReadyToHide(true);
          }, 2000);
        },
        onBegin: () => {
          setTypedDone(false);
        }
      });

      // Destroy Typed instance during cleanup to prevent memory leaks
      return () => {
        typed.destroy();
      };
    }
  }, [typedStrings]);

  useEffect(() => {
    if (readyToHide) {
      setTimeout(() => {
        setTypedDone(true);
      }, 500);
    }
  }, [readyToHide]);

  const startsWithVowel = (word) => {
    return /^[aeiouAEIOU]/.test(word);
  };

  const generateSentence = (strings) => {
    if (strings.length == 0) {
      return "...";
    }
    const items = strings.map((str, index) => {
      // Remove the trailing period for better sentence construction
      const cleanStr = str.replace(/\.$/, "");
      const article = startsWithVowel(cleanStr) ? "an" : "a";
      return <span key={index}>{`${article} ${cleanStr}`}</span>;
    });

    // Join all items with commas except the last one, which uses 'and'
    const lastItem = items.pop();
    return (
      <>
        I am {items.reduce((prev, curr) => [prev, ", ", curr])} and {lastItem}.
      </>
    );
  };

  const loadIntro = () => {
    getMultipleDocs({
      _collection: `intro`,
      _query: false,
      orderByField: "createdAt",
      orderByOption: "asc"
    });
  };
  const loadSocial = () => {
    getSocialLinks({
      _collection: `social_links`,
      _query: false
    });
  };

  useEffect(() => {
    loadIntro();
    loadSocial();
  }, []);

  useEffect(() => {
    if (documents) {
      let data = new Array();
      documents.map((item) => {
        data.push(item.intro_text);
      });
      setTypedStrings(data);
    }
  }, [documents]);

  return (
    <section id="hero" className={`${Styles.hero} section`}>
      <div className={Styles.bg}>
        <Player speed={0.6} className={Styles.lottie} autoplay loop src={"/lottiefiles/hero_bg.json"} />
      </div>

      <Container className={Styles.content} data-aos="fade-up" data-aos-delay="100">
        <div className={Styles.data}>
          <h2>Aidid Alam</h2>
          {!typedDone && (
            <p className={readyToHide ? Styles.readyToHide : ""}>
              I'm {article} <span ref={typedRef} className="typed"></span>
              <span className="typed-cursor typed-cursor--blink" aria-hidden="true"></span>
            </p>
          )}
        </div>
      </Container>

      {typedDone && (
        <>
          <div className={Styles.intro} data-aos="fade-in" data-aos-delay="700">
            <p className={Styles.all_together}>{generateSentence(typedStrings)}</p>
          </div>
        </>
      )}

      {socialLinks && socialLinks.length === 1 && (
        <div className={`${Styles.socialLinks} text-center`}>
          {socialLinks[0].github && socialLinks[0].github_active && (
            <Link to={socialLinks[0].github} className="github" target="_blank">
              <i className="bi bi-github"></i>
            </Link>
          )}
          {socialLinks[0].facebook && socialLinks[0].facebook_active && (
            <Link to={socialLinks[0].facebook} className="facebook" target="_blank">
              <i className="bi bi-facebook"></i>
            </Link>
          )}
          {socialLinks[0].instagram && socialLinks[0].instagram_active && (
            <Link to={socialLinks[0].instagram} className="instagram" target="_blank">
              <i className="bi bi-instagram"></i>
            </Link>
          )}
          {socialLinks[0].email && socialLinks[0].email_active && (
            <Link to={`mailto: ${socialLinks[0].email}`} className="envelope-at-fill" target="_blank">
              <i className="bi bi-envelope-at-fill"></i>
            </Link>
          )}
          {socialLinks[0].skype && socialLinks[0].skype_active && (
            <Link to={socialLinks[0].skype} className="google-plus" target="_blank">
              <i className="bi bi-skype"></i>
            </Link>
          )}
          {socialLinks[0].linkedin && socialLinks[0].linkedin_active && (
            <Link to={socialLinks[0].linkedin} className="linkedin" target="_blank">
              <i className="bi bi-linkedin"></i>
            </Link>
          )}
          {socialLinks[0].medium && socialLinks[0].medium_active && (
            <Link to={socialLinks[0].medium} className="medium" target="_blank">
              <i className="bi bi-medium"></i>
            </Link>
          )}
        </div>
      )}

      <a
        href={userData.resume_url ? userData.resume_url : "#"}
        target="_blank"
        className={Styles.downloadResume}
      >
        <Player
          data-aos="fade-in"
          data-aos-delay="700"
          speed={1}
          className={Styles.downloadDocAnimation}
          autoplay
          loop
          src={"/lottiefiles/download_doc.json"}
        />
        <p>Download Resume</p>
      </a>

      <Player
        data-aos="fade-in"
        data-aos-delay="700"
        speed={1}
        className={Styles.scroll_down_animation}
        autoplay
        loop
        src={"/lottiefiles/scroll_down.json"}
      />
    </section>
  );
};

export default Hero;
