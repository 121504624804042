import React, { useEffect, useState } from "react";
import { Button, Form, Row, Spinner } from "react-bootstrap";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createReactEditorJS } from "react-editor-js";
import { useCollectionDocs } from "../../../../hooks/useCollectionDocs";
import { useAddDocs } from "../../../../hooks/useAddDocs";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { storage } from "../../../../firebase/config";
import CropModal from "../../../../components/ImageCrop/CropModal";
import { EDITOR_JS_TOOLS, getRandomString } from "../../../../utils/Utils";
import Editor from "../../../../components/Editor/Editor";
import { Link, useNavigate } from "react-router-dom";

const PortfolioAddItem = () => {
  const { documents: categories, getMultipleDocs } = useCollectionDocs();
  const { documents: skills_data, getMultipleDocs: getSkillDocs } = useCollectionDocs();
  const [skills, setSkills] = useState([]);
  const { addADoc, isPending: isAddPending, error: addError } = useAddDocs();
  const [addProtfolioPending, setAddProtfolioPending] = useState(false);
  const navigate = useNavigate();

  const [thumbnailSrc, setThumbnailSrc] = useState(null);
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const [editorData, setEditorData] = useState(null);
  const [showSmallImageCropModal, setShowSmallImageCropModal] = useState(false);
  const [blocks, setBlocks] = useState([]);

  const initialValues = {
    title: "",
    categories: [],
    skills: [],
    description: "",
    external_url: ""
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    categories: Yup.array().min(1, "Select at least one category").required("Categories are required"),
    skills: Yup.array().min(1, "Select at least one skill").required("Skills are required"),
    description: Yup.string().required("Description is required"),
    external_url: Yup.string().url("Must be a valid URL").nullable()
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setAddProtfolioPending(true);
      try {
        const imageUrl = await uploadImageToStorage(thumbnailUrl);
        const newItem = {
          title: values.title,
          categories: values.categories,
          skills: values.skills,
          description: values.description,
          external_url: values.external_url,
          image_url: imageUrl,
          data: blocks // Optional, depends on your setup with Editor.js
        };

        await addADoc("portfolio", { ...newItem, active: false });
        resetForm();
        setThumbnailSrc(null);
        setThumbnailUrl(null);
        setEditorData(null);
        setAddProtfolioPending(false);
        window.location.replace(`${window.location.protocol}//${window.location.host}/dashboard/portfolio`);
      } catch (error) {
        console.error("Error adding portfolio item:", error);
        setAddProtfolioPending(false);
      }
    }
  });

  const uploadImageToStorage = async (imageUrl) => {
    const imgRef = ref(storage, `portfolio_images/${getRandomString(23)}`);
    await uploadString(imgRef, imageUrl, "data_url");
    return await getDownloadURL(imgRef);
  };

  const cropSmallProfileImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setShowSmallImageCropModal(true);
      const reader = new FileReader();
      reader.addEventListener("load", () => setThumbnailSrc(reader.result?.toString() || ""));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    getMultipleDocs({
      _collection: "portfoliocat",
      _query: false,
      orderByField: "createdAt",
      orderByOption: "asc"
    });
    getSkillDocs({
      _collection: "skills",
      _query: false,
      orderByField: "createdAt",
      orderByOption: "asc"
    });
  }, []);

  useEffect(() => {
    if (skills_data && Array.isArray(skills_data) && skills_data.length > 0) {
      let skillFilter = [];
      skills_data.map((item) => {
        if (item.skills) {
          skillFilter = [...skillFilter, ...item.skills];
        }
      });
      skillFilter = skillFilter.filter(
        (item, index, self) => index === self.findIndex((t) => t.tag === item.tag)
      );

      setSkills(skillFilter);
    }
  }, [skills_data]);

  return (
    <div className="container my-5">
      <Form
        onSubmit={(e) => {
          e.preventDefault();

          if (!thumbnailUrl) {
            formik.setFieldError("thumbnail", "Image is required");
            return;
          }

          formik.handleSubmit(e);
        }}
      >
        <Row>
          <div className="col-12">
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.title && !!formik.errors.title}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.title}</Form.Control.Feedback>
            </Form.Group>
          </div>

          <div className="col-md-8">
            <Form.Group className="mb-3">
              <Form.Label>Detail Data</Form.Label>
              <Editor data={blocks} onChange={setBlocks} editorblock="editorjs-container" />
            </Form.Group>
          </div>

          <div className="col-md-3">
            <Form.Group className="mb-3">
              <Form.Label>Image (16x9)</Form.Label>
              <div className="row">
                <div className="col-12">
                  {thumbnailUrl && <img src={thumbnailUrl} alt="Small" className="w-100 img-fluid mt-2" />}
                  <div className="mb-3">
                    <label htmlFor="thumbnail" className="form-label">
                      Thumbnail
                    </label>
                    <input
                      id="thumbnail"
                      accept="images/*"
                      type="file"
                      name="thumbnil_image"
                      className="form-control"
                      onChange={(event) => {
                        cropSmallProfileImage(event);
                        formik.setFieldError("thumbnail", null);
                      }}
                    />
                  </div>

                  {formik.errors.thumbnail && (
                    <div className="invalid-feedback d-block">{formik.errors.thumbnail}</div>
                  )}
                </div>
              </div>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Category</Form.Label>
              {categories && Array.isArray(categories) && categories.length > 0 && (
                <>
                  <Select
                    className={`${
                      formik.errors.categories ? "border border-danger rounded-2 border-1" : ""
                    } `}
                    options={categories.map((cat) => ({ value: cat.id, label: cat.name }))}
                    isMulti
                    name="categories"
                    value={formik.values.categories}
                    onChange={(value) => {
                      formik.setFieldValue("categories", value);
                    }}
                    onBlur={formik.handleBlur}
                    classNamePrefix="react-select"
                    placeholder="Select categories..."
                    isInvalid={formik.touched.categories && !!formik.errors.categories}
                  />
                </>
              )}
              {formik.errors.categories && (
                <div className="invalid-feedback d-block">{formik.errors.categories}</div>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Skill</Form.Label>
              {skills && Array.isArray(skills) && skills.length > 0 && (
                <>
                  <Select
                    className={`${formik.errors.skills ? "border border-danger rounded-2 border-1" : ""} `}
                    options={skills.map((Skill) => ({ value: Skill.tag, label: Skill.name }))}
                    isMulti
                    name="skills"
                    value={formik.values.skills}
                    onChange={(value) => {
                      formik.setFieldValue("skills", value);
                    }}
                    onBlur={formik.handleBlur}
                    classNamePrefix="react-select"
                    placeholder="Select skills..."
                    isInvalid={formik.touched.skills && !!formik.errors.skills}
                  />
                </>
              )}
              {formik.errors.skills && <div className="invalid-feedback d-block">{formik.errors.skills}</div>}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.description && !!formik.errors.description}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.description}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>External URL (optional)</Form.Label>
              <Form.Control
                type="url"
                name="external_url"
                value={formik.values.external_url}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.external_url && !!formik.errors.external_url}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.external_url}</Form.Control.Feedback>
            </Form.Group>
          </div>

          <div className="col-8 d-flex justify-content-center">
            <Button className="btn btn-success btn-lg mx-2 px-5" type="submit" disabled={isAddPending}>
              {addProtfolioPending ? (
                <Spinner animation="border" size="sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "Save"
              )}
            </Button>

            <a href={"/dashboard/portfolio"} className="btn btn-warning btn-lg mx-2">
              Return
            </a>
          </div>
        </Row>
      </Form>

      <CropModal
        show={showSmallImageCropModal}
        setShow={setShowSmallImageCropModal}
        setImage={(localStorageVar, url) => {
          setThumbnailSrc(url);
          setThumbnailUrl(url);
        }}
        imgSrc={thumbnailSrc}
        aspect={16 / 9}
        localStorageVar="thumbnail"
      />

      {addError && <p className="text-danger">{addError}</p>}
    </div>
  );
};

export default PortfolioAddItem;
