import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Intro from "./Intro/Intro";
import { AuthHeader } from "../authHeader/AuthHeader";
import "./Admin.css";
import SocialLinks from "./SocialLinks/SocialLinks";
import About from "./About/About";
import Stats from "./Stats/Stats.js";
import Skills from "./Skills/Skills.js";
import Resume from "./Resume/Resume.js";
import Service from "./Service/Service.js";
import Contact from "./Contact/Contact.js";
import PortfolioCat from "./PortfolioCat/PortfolioCat.js";
import Portfolio from "./Portfolio/Portfolio.js";
import PortfolioAdd from "./Portfolio/PortfolioAdd.js";
import PortfolioEdit from "./Portfolio/PortfolioEdit.js";

const Admin = () => {
  const location = useLocation();
  const [showNavBar, setShowNavbar] = useState(true);
  const navItems = [
    { url: "", name: "Intro", icon: "bi bi-house" },
    { url: "/social-links", name: "Social Links", icon: "bi bi-house" },
    { url: "/about", name: "About", icon: "bi bi-house" },
    { url: "/stats", name: "Stats", icon: "bi bi-house" },
    { url: "/skills", name: "Skills", icon: "bi bi-house" },
    { url: "/resume", name: "Experience", icon: "bi bi-house" },
    { url: "/portfolio-category", name: "Portfolio Cat", icon: "bi bi-house" },
    { url: "/portfolio", name: "Portfolio", icon: "bi bi-house" },
    { url: "/service", name: "Service", icon: "bi bi-house" },
    { url: "/contact", name: "Contact", icon: "bi bi-house" }
    // Add more items as needed
  ];

  useEffect(() => {
    if (location.pathname.includes("dashboard/portfolio/")) {
      setShowNavbar(false);
    } else {
      setShowNavbar(true);
    }
  }, [location]);

  return (
    <>
      <AuthHeader showNavBar={showNavBar} navItems={navItems} />
      <main className={`${showNavBar ? "dashboard" : "full-width-dashboard"}`}>
        <Routes>
          <Route path="" element={<Intro />} />
          <Route path="/social-links" element={<SocialLinks />} />
          <Route path="/about" element={<About />} />
          <Route path="/stats" element={<Stats />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/portfolio-category" element={<PortfolioCat />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfolio/add" element={<PortfolioAdd />} />
          <Route path="/portfolio/:portfolio_id" element={<PortfolioEdit />} />
          <Route path="/service" element={<Service />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </main>
    </>
  );
};

export default Admin;
