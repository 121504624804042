import { useToastStateContext } from '../context/ToastContext';

export function useToast() {
  const {dispatch} = useToastStateContext();

  function toast(type, message) {
    const id = Math.random().toString(36).substr(2, 9);
    dispatch({ type: 'ADD_TOAST', toast: { type, message, id } });
  }

  return toast;
}
