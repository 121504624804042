import React, { useEffect } from "react";
import Styles from "./Resume.module.css";
import { Container, Row } from "react-bootstrap";
import { useGetDoc } from "../../../hooks/useGetDoc";
import { documentId } from "firebase/firestore";
import { Player } from "@lottiefiles/react-lottie-player";

const Resume = () => {
  const { getSingleDocWithQuery, data, isPending, error } = useGetDoc();
  useEffect(() => {
    getSingleDocWithQuery("users", [[documentId(), "==", process.env.REACT_APP_ADMIN_ID]]);
  }, []);
  return (
    <section id="resume" className={`${Styles.resume} section`}>
      <div className="container section-title" data-aos="fade-up">
        <h2>Resume</h2>
        {data && Array.isArray(data) && data.length > 0 && <p>{data[0].resume_area}</p>}
        {data && Array.isArray(data) && data.length > 0 && (
          <a
            href={data[0].resume_url ? data[0].resume_url : "#"}
            target="_blank"
            className={Styles.downloadResume}
          >
            <Player
              data-aos="fade-in"
              data-aos-delay="700"
              speed={1}
              className={Styles.downloadDocAnimation}
              autoplay
              loop
              src={"/lottiefiles/download_doc.json"}
            />
            <p>Download Resume</p>
          </a>
        )}
      </div>

      <Container>
        <Row>
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
            <h3 className={Styles.resumeTitle}>Professional Experience</h3>
            <div className={Styles.resumeItem}>
              <h4>Full Stack Developer (Remote)</h4>
              <h5>2021 - Present</h5>
              <p>
                <em>
                  <a href="https://sugartaste.co.jp/" target="_blank">
                    Sugartaste Crop. Tokyo, JP
                  </a>
                </em>
              </p>
              <ul>
                <li>Worked in PHP STACK & MERN STACK.</li>
                <li>
                  Developed functional databases, applications, and Linux Servers to support websites on the
                  back end and frontend.
                </li>
                <li>Utilized SQL (Eloquent ORM, Sequelize) and NoSQL (Firebase, MongoDB) databases.</li>
                <li>Utilized ExpressJS, Node.js, TypeScript, and Lumen for backend development.</li>
                <li>Utilized Vue.js, React, and Next.js for frontend development.</li>
                <li>
                  Utilized Git for development and project management, including version control, branching,
                  merging, and coordinating team efforts.
                </li>
                <li>Handled Komoju payment gateway for payments and subscriptions.</li>
                <li>Led SASS product development and played a key role in maintaining the product.</li>
                <li>
                  Contributed decisively to team meetings by actively participating in decision-making
                  processes.
                </li>
              </ul>
            </div>

            <div className={Styles.resumeItem}>
              <h4>Full Stack Developer (Hybrid)</h4>
              <h5>2019 - 2020</h5>
              <p>
                <em>
                  <a href="https://www.facebook.com/thethirdeye2K19/" target="_blank">
                    Third EYE, Chittagong, BD
                  </a>
                </em>
              </p>
              <ul>
                <li>
                  Developed functional databases, applications, and servers to support websites on the back
                  end.
                </li>
                <li>Worked with Laravel & Wordpress</li>
                <li>
                  Contributed ideas and suggestions in team meetings and delivered updates on deadlines,
                  designs, and enhancements
                </li>
                <li>Provide technical support during software installation or configuration.</li>
              </ul>
            </div>
          </div>

          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <h3 className={Styles.resumeTitle}>Education</h3>
            <div className={Styles.resumeItem}>
              <h4>BSC in Mechatronics &amp; Industrial Engineering</h4>
              <h5>2018 - 2023</h5>
              <p>
                <em>
                  <a href="https://cuet.ac.bd/" target="_blank">
                    Chittagong University of Engineering &amp; Technology, BD
                  </a>
                </em>
              </p>
              <p>
                My Bachelor's degree has equipped me with skills in control systems, machine programming,
                embedded systems.
              </p>
            </div>

            <div className={Styles.resumeItem}>
              <h4>Higher Secondary</h4>
              <h5>2014 - 2017</h5>
              <p>
                <em>
                  <a href="http://www.gshac.gov.bd/" target="_blank">
                    Govt. Syed Hatem Ali College, BD
                  </a>
                </em>
              </p>
              <p>
                I successfully completed my Higher Secondary education in the Science stream with a GPA of 5
                out of 5
              </p>
            </div>
            <div className={Styles.resumeItem}>
              <h4>Secondary School</h4>
              <h5>2006 - 2014</h5>
              <p>
                <em>
                  <a href="https://bzsb.edu.bd/" target="_blank">
                    Barisal Zilla School, BD
                  </a>
                </em>
              </p>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default Resume;
