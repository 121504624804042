import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import AOS from "aos";
import "aos/dist/aos.css";
import "glightbox/dist/css/glightbox.min.css";

import ToastDiv from "./components/toast/ToastDiv";
import { useAuthContext } from "./hooks/useAuthContext";

import Login from "./pages/login/Login";
import { useEffect } from "react";
import Auth from "./pages/auth/Auth";
import { UserDetailsProvider } from "./context/UserDetailsContext";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animation in milliseconds
      once: true // Whether animation should happen only once - while scrolling down
    });
  }, []);

  const { user, authIsReady } = useAuthContext();
  return (
    <>
      {authIsReady && (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="login"
              element={
                !user ? (
                  <div className="topmargin-no-links">
                    <Login />
                  </div>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />

            <Route
              path="dashboard/*"
              element={
                user ? (
                  <>
                    <UserDetailsProvider>
                      <Auth />
                    </UserDetailsProvider>
                  </>
                ) : (
                  <Navigate to="/login" />
                )
              }
            ></Route>
          </Routes>
        </BrowserRouter>
      )}
      <ToastDiv />
    </>
  );
}

export default App;
