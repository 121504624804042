import React, { useEffect, useState } from "react";
import { useCollectionDocs } from "../../../../hooks/useCollectionDocs";
import { useSetDoc } from "../../../../hooks/useSetDoc"; // Import the custom hook

import { Button, Modal, Table, Container, Row, ButtonGroup, Spinner } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDeleteDocs } from "../../../../hooks/useDeleteDocs";
import { useAddDocs } from "../../../../hooks/useAddDocs";

const validationSchema = Yup.object().shape({
  intro_text: Yup.string()
    .min(10, "Intro text must be at least 10 characters long")
    .required("Intro text is required")
});

const Intro = () => {
  const { documents, isPending, getMultipleDocs } = useCollectionDocs();
  const { firebaseSetDoc, isPending: isUpdating, isConfirmed, error } = useSetDoc();
  const { addADoc, isPending: isAddPending, error: addError } = useAddDocs();
  const [datas, setDatas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({ id: "", intro_text: "" });
  const { deleteDocument, isPending: deletePending } = useDeleteDocs();

  const loadMore = () => {
    getMultipleDocs({
      _collection: `intro`,
      _query: false,
      orderByField: "createdAt",
      orderByOption: "asc"
    });
  };

  useEffect(() => {
    loadMore();
  }, []);

  useEffect(() => {
    if (documents) {
      setDatas(documents);
    }
  }, [documents]);

  const addData = () => {
    setModalData({ id: "", intro_text: "" });
    setShowModal(true);
  };

  const editData = (data) => {
    setModalData(data);
    setShowModal(true);
  };

  const deleteData = async (id) => {
    await deleteDocument("intro", id);
    loadMore();
  };

  const handleModalClose = () => {
    setShowModal(false);
    setModalData({ id: "", intro_text: "" });
  };

  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      if (modalData.id) {
        await firebaseSetDoc("intro", values, values.id, true);
      } else {
        await addADoc("intro", values);
      }
      loadMore(); // Refresh the data after updating
    } catch (error) {
      console.error("Error updating document: ", error);
    } finally {
      setSubmitting(false);
      setShowModal(false);
    }
  };

  return (
    <>
      <Container>
        <Row>
          <div className="col-12 text-end my-3">
            <Button variant="success" onClick={addData}>
              Add New Data
            </Button>
          </div>
        </Row>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Intro Text</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {datas.map((data) => (
              <tr key={data.id}>
                <td>{data.intro_text}</td>
                <td>
                  <ButtonGroup>
                    <Button variant="primary" size="sm" onClick={() => editData(data)}>
                      Edit
                    </Button>
                    <Button variant="danger" size="sm" onClick={() => deleteData(data.id)}>
                      {deletePending ? (
                        <Spinner animation="border" size="sm" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        "Delete"
                      )}
                    </Button>
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>

      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalData.id ? "Edit Data" : "Add New Data"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={modalData}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
            enableReinitialize={true}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="form-group">
                  <label htmlFor="intro_text">Intro Text</label>
                  <Field type="text" name="intro_text" className="form-control" />
                  <ErrorMessage name="intro_text" component="div" className="text-danger" />
                </div>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleModalClose}>
                    Close
                  </Button>
                  <Button variant="primary" type="submit" disabled={isSubmitting || isUpdating}>
                    {isSubmitting || isUpdating || isAddPending ? (
                      <Spinner animation="border" size="sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      "Save changes"
                    )}
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Intro;
