import React, { useEffect, useState } from "react";
import Styles from "./Portfolio.module.css";
import { Link } from "react-router-dom";
import { Ratio } from "react-bootstrap";

const PortfolioItem = ({ item }) => {
  console.log(item);
  const [filter, setFilter] = useState("");
  const [itemUrl, setItemUrl] = useState("");

  useEffect(() => {
    if (item.categories && Array.isArray(item.categories) && item.categories.length > 0) {
      const cat = item.categories.map((item) => {
        return "filter-" + item.label;
      });

      setFilter(cat.join(" "));
    }

    if (item.data && Array.isArray(item.data) && item.data.length > 0) {
      setItemUrl("/portfolio/" + item.id);
    } else {
      setItemUrl(item.external_url);
    }
  }, [item]);
  return (
    <div className={`col-lg-4 col-md-6 portfolio-item isotope-item ${filter}  ${Styles.item}`}>
      <Link to={itemUrl} target={itemUrl.startsWith("/portfolio/") ? "_self" : "_blank"}>
        <div className={`${Styles.portfolioContent}`}>
          <Ratio aspectRatio="16x9">
            <img src={item.image_url} className="img-fluid" alt="" />
          </Ratio>
          <h4 className="mb-0">{item.title}</h4>
          <div className={Styles.portfolioInfo}>
            <p>{item.description}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default PortfolioItem;
