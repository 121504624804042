import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useUserDetailsContext } from "../../../../context/UserDetailsContext";
import { useSetDoc } from "../../../../hooks/useSetDoc";
import { Spinner } from "react-bootstrap";

const Stats = () => {
  const { userData, isPending, id } = useUserDetailsContext();
  const { firebaseSetDoc, isPending: isUpdating, isConfirmed, error } = useSetDoc();

  const formik = useFormik({
    initialValues: {
      total_organization: userData?.total_organization || "",
      experience_years: userData?.experience_years || "",
      projects: userData?.projects || "",
      published_libraries: userData?.published_libraries || "",
      published_paper: userData?.published_paper || ""
    },
    validationSchema: Yup.object({
      total_organization: Yup.number().required("Required"),
      experience_years: Yup.number().required("Required"),
      projects: Yup.number().required("Required"),
      published_libraries: Yup.number().required("Required"),
      published_paper: Yup.number().required("Required")
    }),
    onSubmit: async (values) => {
      await firebaseSetDoc("users", values, id, true);
    }
  });

  return (
    <div className="container mt-5">
      <h1>Stats</h1>
      {isPending ? (
        <p>Loading...</p>
      ) : (
        <form onSubmit={formik.handleSubmit} className="needs-validation" noValidate>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="total_organization" className="form-label">
                  Total Organizations
                </label>
                <input
                  id="total_organization"
                  name="total_organization"
                  type="number"
                  className={`form-control ${
                    formik.touched.total_organization && formik.errors.total_organization ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  value={formik.values.total_organization}
                />
                {formik.touched.total_organization && formik.errors.total_organization ? (
                  <div className="invalid-feedback">{formik.errors.total_organization}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="experience_years" className="form-label">
                  Experience Years
                </label>
                <input
                  id="experience_years"
                  name="experience_years"
                  type="number"
                  className={`form-control ${
                    formik.touched.experience_years && formik.errors.experience_years ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  value={formik.values.experience_years}
                />
                {formik.touched.experience_years && formik.errors.experience_years ? (
                  <div className="invalid-feedback">{formik.errors.experience_years}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="projects" className="form-label">
                  Projects
                </label>
                <input
                  id="projects"
                  name="projects"
                  type="number"
                  className={`form-control ${
                    formik.touched.projects && formik.errors.projects ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  value={formik.values.projects}
                />
                {formik.touched.projects && formik.errors.projects ? (
                  <div className="invalid-feedback">{formik.errors.projects}</div>
                ) : null}
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="published_libraries" className="form-label">
                  Published Libraries
                </label>
                <input
                  id="published_libraries"
                  name="published_libraries"
                  type="number"
                  className={`form-control ${
                    formik.touched.published_libraries && formik.errors.published_libraries
                      ? "is-invalid"
                      : ""
                  }`}
                  onChange={formik.handleChange}
                  value={formik.values.published_libraries}
                />
                {formik.touched.published_libraries && formik.errors.published_libraries ? (
                  <div className="invalid-feedback">{formik.errors.published_libraries}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="published_paper" className="form-label">
                  Published Papers
                </label>
                <input
                  id="published_paper"
                  name="published_paper"
                  type="number"
                  className={`form-control ${
                    formik.touched.published_paper && formik.errors.published_paper ? "is-invalid" : ""
                  }`}
                  onChange={formik.handleChange}
                  value={formik.values.published_paper}
                />
                {formik.touched.published_paper && formik.errors.published_paper ? (
                  <div className="invalid-feedback">{formik.errors.published_paper}</div>
                ) : null}
              </div>
            </div>

            <div className="col-12 text-center mb-5">
              <button type="submit" style={{ width: "200px" }} className="btn btn-primary btn-lg">
                {isUpdating ? (
                  <Spinner animation="border" size="sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default Stats;
