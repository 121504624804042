import { useState, useEffect } from "react";
import { signOut } from "firebase/auth";
import { auth, firebaseErrors } from "../firebase/config";
import { useAuthContext } from "./useAuthContext";
import { useToast } from "./useToast";

export const useLogout = () => {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  const { dispatch } = useAuthContext();
  const toast = useToast();

  const logout = (showToast = true) => {
    setError(null);
    setIsPending(true);

    signOut(auth)
      .then(() => {
        setIsPending(false);
        dispatch({ type: "LOGOUT", payload: null });
        if (showToast) {
          toast("warning", "Logged out.");
          localStorage.clear();
        }
      })
      .catch((err) => {
        // console.log(firebaseErrors[err.code] || err.message);
        setError(firebaseErrors[err.code] || err.message);
        setIsPending(false);
      });
  };

  return { isPending, error, logout };
};
