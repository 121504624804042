import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useUserDetailsContext } from "../../context/UserDetailsContext";
import Admin from "./admin/Admin";

const Auth = () => {
  const { userData, isPending, id } = useUserDetailsContext();

  return (
    <>
      {isPending && (
        <div className="position-absolute top-50 w-100 text-center">
          <Spinner className="" animation="grow" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}

      {userData && userData.group === "admin" && <Admin />}
    </>
  );
};

export default Auth;
