import React, { useEffect, useRef, useState } from "react";
import Styles from "./AuthHeader.module.css";
import { Link, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useLogout } from "../../../hooks/useLogout";
import { useUserDetailsContext } from "../../../context/UserDetailsContext";

export const AuthHeader = ({ navItems, showNavBar }) => {
  const headerRef = useRef(null);
  const headerToggleBtnRef = useRef(null);
  const location = useLocation();
  const { userData, isPending } = useUserDetailsContext();
  const { logout } = useLogout();
  const [items, setItems] = useState(
    navItems.map((item) => ({
      ...item,
      url: `/dashboard${item.url}`,
      active: false
    }))
  );

  useEffect(() => {
    setItems((prevItems) =>
      prevItems.map((item) => ({
        ...item,
        active: item.url === location.pathname
      }))
    );
  }, [location.pathname]);

  const headerToggle = () => {
    if (headerRef.current && headerToggleBtnRef.current) {
      headerRef.current.classList.toggle(Styles.headerShow);

      headerToggleBtnRef.current.classList.toggle("bi-list");
      headerToggleBtnRef.current.classList.toggle("bi-x");
    }
  };

  useEffect(() => {
    headerToggle();
  }, [items]);

  return (
    <>
      <Navbar expand="lg" className={`bg-body-tertiary ${Styles.navbar}`}>
        <Container fluid>
          <Navbar.Brand href="/" className={Styles.navText}>
            Dashboard<br></br>
            <span className="d-inline d-sm-none">
              {userData.fname} {userData.lname}
            </span>
          </Navbar.Brand>
          <NavDropdown
            className={`${Styles.navDropDown}`}
            title={`${userData.fname} ${userData.lname}`}
            id="navbarScrollingDropdown"
          >
            <NavDropdown.Item
              onClick={(e) => {
                e.preventDefault();
                logout(true);
              }}
              href="#"
            >
              Logout
            </NavDropdown.Item>
          </NavDropdown>
        </Container>
      </Navbar>

      <i
        ref={headerToggleBtnRef}
        onClick={headerToggle}
        className={`${Styles.headerToggle} d-xl-none bi bi-list`}
      ></i>

      {showNavBar && (
        <header ref={headerRef} className={`${Styles.header} d-flex flex-column`}>
          <nav className={Styles.navmenu}>
            <ul>
              {items.map((item) => (
                <li key={item.url}>
                  <Link to={item.url} className={item.active ? Styles.active : ""}>
                    <i className={`${item.icon} ${Styles.navicon}`}></i> {item.name}
                  </Link>
                </li>
              ))}
              <li>
                <Link
                  to={"#"}
                  onClick={(e) => {
                    e.preventDefault();
                    logout(true);
                  }}
                  className={`d-block d-sm-none`}
                >
                  <i className={`bi bi-box-arrow-left ${Styles.navicon}`}></i> Logout
                </Link>
              </li>
            </ul>
          </nav>
        </header>
      )}
    </>
  );
};
